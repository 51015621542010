@import "../../../shared";

.CommentOverlayRatingInput {
  padding: 0em 1em 1em 1em;
  &.CommentOverlayRating--disabled {
    * {
      pointer-events: none !important;
    }
  }
}

.CommentOverlayRatingInput--star {
  @include hide-text;
  width: 40px;
  height: 40px;
  display: inline-block;
  background: url(../../../images/icon-muffin.svg) center center no-repeat;
  background-size: auto 100%;
  opacity: 0.5;
  vertical-align: top;
}

.CommentOverlayRatingInput--interactable {
  cursor: pointer;
}

.CommentOverlayRatingInput--star__filled {
  opacity: 1;
}
