@import "../../shared";

.InputTooltip--container {
  pointer-events: none;
  transition: opacity 0.2s ease-out;
  pointer-events: none;
}

.InputTooltip--message {
  font-size: 15px;
  line-height: 20px;
  background: $colorLightBrown;
  padding: 1.5rem 2rem 1rem 1rem;
  border-radius: 5px;
}

.InputTooltip--close {
  position: absolute;
  top: 9px;
  right: 10px;
  width: 16px;
  height: 16px;
  pointer-events: auto;

  background: url(../../images/icon-close.svg) no-repeat;
  background-size: contain;

  &:hover {
    cursor: pointer;
  }
}
