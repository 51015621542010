@import "../../shared";

.Header {
  padding: 1em 0;
  position: relative;

  @media(min-width: $screen-lg-min) {
    height: 147px;
    padding: 1em 0 0;
    background: url(../../images/backmomente-menu-bg2.jpg) 50% 0 no-repeat;
    background-size: cover;
  }

  h2 {
    @include hide-text;
    transition: all 0.2s;
    background: url(../../images/backmomente-logo2.png) 0 center no-repeat;
    width: 220px;
    height: 52px;
    background-size: contain;

    @media(min-width: $screen-lg-min) {
      width: 220px;
      height: 52px;
      display: inline-block;
    }
  }

  .Header--menu {
    position: absolute;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);

    li {
      display: inline-block;

      button, a {
        @include hide-text;

        &[href*="suche"] {
          margin-left: 0;
        }

        width: 30px;
        height: 30px;
        background: red;
        margin-left: 20px;

        background: url(../../images/icon-magnifier.svg) center center no-repeat;
        background-size: contain;

        &.Header--icon--burger {
          background: url(../../images/icon-burger.svg) center center no-repeat;
          background-size: contain;
        }

        &.Header--icon--account {
          background: url(../../images/icon-user.svg) center center no-repeat;
          background-size: contain;
        }
      }
    }
  }
}

.Header--header-wrapper {
  position: relative;
}

.Header--greeting {
  position: absolute;
  top: 24px;
  right: 20px;
  padding: 6px;
  border-radius: 3px;
  background: rgba(white, 0.75);
  color: $colorBrown;
  font-size: 14px;
  font-weight: 700;
  display: none;

  @media (min-width: 1200px) {
    display: flex;
    align-items: center;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .UserCrownLevel--crown {
    margin-right: 6px;
  }

}

.Header--greetingUsername {
  margin-left: 5px;
}

.Header--mobile-menu {
  @media(min-width: $screen-lg-min) {
    display: none;
  }
}

.Header--icon--figure {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.Header--icon--figure-wrapper {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 30px;
  background: silver;
}

.Header--icon--figure--image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Header--icon--account__logged-in {
  background: none !important;
}

.Header--cm {
  position: absolute;
  top: -14px;
  right: 127px;
  display: none;
  @media(min-width: $screen-lg-min) {
    top: 0px;
    right: 150px;
    display: block;
  }
}
.Header--cm-icon {
  width: 65px;

}
