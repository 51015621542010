.ButtonDistorted {
  appearance: none;
  border: none;
  background: transparent;
  color: #fff;
  background: url(../../../images/button-background.png) top left no-repeat;
  background-size: 100% 100%;
  line-height: 1.25em;
  padding: 0.5em 2em;
  text-transform: uppercase;
  font-weight: bold;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
}

.ButtonDistorted[data-inline=true] {
  position: static;
  transform: none;
}

.ButtonDistorted[disabled] {
  cursor: not-allowed;
  opacity: 0.33;
}

