.MessageOverlay {
  background: #fff;
}
@media (min-width: 1200px) {
  .MessageOverlay {
    margin: 20vh auto 0;
    background: transparent;
  }
}

.MessageOverlay--inner {
  background-color: #fff;
  text-align: center;
  padding: 1em;
}
.MessageOverlay--footer {
  margin: 1em;
}

