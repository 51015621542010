.FormTextarea {
  line-height: 30px;
  font-size: 14px;
  padding: 0 10px;
  border: none;
  box-shadow: 3px 0 13px rgba(0, 0, 0, 0.09);
  font-family: "Lato", sans-serif;
  width: 100%;
}
.FormTextarea::placeholder {
  color: silver;
}

.FormTextarea[data-error=true] {
  background-color: #ffcccc !important;
}
.FormTextarea[data-error=true]::placeholder {
  color: red;
  text-transform: uppercase;
}

.FormTextarea[data-block=true] {
  width: 100%;
}

.FormTextarea[data-spaced=true] {
  margin: 1em 0;
}

.FormTextarea[data-dark=true] {
  background-color: #ecebeb;
  color: #000;
}
.FormTextarea[data-dark=true]::placeholder {
  color: rgb(182.8474576271, 193.0508474576, 198.1525423729);
}

.FormTextarea[data-lowercase=true]::placeholder {
  text-transform: none;
}

.FormTextarea[data-noshadow=true] {
  box-shadow: none;
}

.FormTextarea[data-hidden=true] {
  display: none;
}

