@import "../../shared";

.LoginComponent {
  display:none;

  @media(min-width: $screen-lg-min) {
    display:block;
    width:auto;
    position:absolute;
    top:50%;
    right:15px;
    transform: translateY(-50%);
  }
}

.LoginComponent[data-loading="true"] {
  opacity: 0.5;
}

.LoginComponent--inline-label {
  display:inline-block;
  margin-left: 1em;
  position: relative;
  &:first-of-type {
    margin-left: 0;
  }
}


.LoginComponent--link {
  position:absolute;
  top:100%;
  right:0;
  appearance: none;
  text-align: right;
  background: rgba($colorBrown, 0.56);
  color: #fff;
  border:none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 2px 10px;
  font-weight: bold;
  text-decoration: none;
  font-size: 12px;

  transition: background 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    background: $colorBrown;
  }

}
