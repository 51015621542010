.SignInUpOverlay {
  height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background: #fff;
  box-sizing: border-box;
  padding: 0;
  position: relative;
}
@media (min-width: 1200px) {
  .SignInUpOverlay {
    border-radius: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    width: 1200px;
    max-height: calc(100vh - 40px);
  }
}
.SignInUpOverlay h1 {
  font-size: 4.5rem;
  margin-bottom: 1.5rem;
}
.SignInUpOverlay a {
  color: #904b27;
}
.SignInUpOverlay input {
  font-size: 20px;
  line-height: 42px;
  height: 42px;
}
.SignInUpOverlay .FormLabel {
  margin-bottom: 0.8rem;
}

.SignInUpOverlay--inner {
  position: relative;
}
.SignInUpOverlay--inner .col-lg-8 {
  padding-left: 0;
  padding-right: 0;
}

.SignInUpOverlay--title {
  font-size: 1.75rem;
  margin: 1rem 0 3rem 0;
  text-align: center;
  font-weight: 600;
}

.SignInUpOverlay--houseInfo {
  display: flex;
  justify-content: space-between;
}
.SignInUpOverlay--houseInfo > *:first-of-type {
  width: calc(100% - 92px);
}
.SignInUpOverlay--houseInfo > *:last-of-type {
  width: 82px;
}

.SignInUpOverlay--confirmSignupTermsContainer {
  display: block;
  padding: 2rem 0 1rem 0;
}

.SignInUpOverlay--confirmSignup {
  line-height: 27px;
  margin-bottom: 12px;
}
@media (min-width: 1200px) {
  .SignInUpOverlay--confirmSignup {
    width: 70%;
    margin: 0 auto 12px auto;
  }
  .SignInUpOverlay--confirmSignup:first-of-type input {
    margin-top: 1px;
  }
  .SignInUpOverlay--confirmSignup:last-of-type {
    margin-bottom: 0;
  }
}
.SignInUpOverlay--confirmSignup span {
  text-align: left;
}
.SignInUpOverlay--confirmSignup:last-of-type {
  margin-bottom: 0;
}
.SignInUpOverlay--confirmSignup input {
  margin-top: 3px;
  height: auto;
}
.SignInUpOverlay--confirmSignup label {
  font-size: 14px;
  line-height: 27px;
}
@media (min-width: 620px) {
  .SignInUpOverlay--confirmSignup {
    line-height: normal;
    display: flex;
  }
  .SignInUpOverlay--confirmSignup input {
    zoom: 2;
  }
}

.SignInUpOverlay--sellingPoints {
  text-align: center;
  margin: 1rem 0 2rem 0;
}
@media (min-width: 900px) {
  .SignInUpOverlay--sellingPoints {
    width: 70%;
    margin: 0 auto 3rem auto;
  }
}
.SignInUpOverlay--sellingPoints li {
  position: relative;
  font-size: 1.75rem;
  line-height: 3rem;
}
@media (min-width: 1100px) {
  .SignInUpOverlay--sellingPoints li {
    display: inline-block;
    margin-right: 7rem;
  }
}
.SignInUpOverlay--sellingPoints li:nth-of-type(2) {
  margin-right: 0;
}
.SignInUpOverlay--sellingPoints li:nth-of-type(3) {
  margin-right: 0;
}
.SignInUpOverlay--sellingPoints li:last-of-type {
  margin-right: 0;
}
.SignInUpOverlay--sellingPoints li:before {
  content: "";
  background: url(../../images/kirsche.svg) no-repeat;
  background-size: contain;
  width: 20px;
  height: 25px;
  display: block;
  position: absolute;
  top: 2px;
  left: -26px;
}

.SignInUpOverlay--aboveFold {
  padding-bottom: 2rem;
}
@media (min-width: 720px) {
  .SignInUpOverlay--aboveFold {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

.SignInUpOverlay--header {
  margin-bottom: 3rem;
}

.SignInUpOverlay--form-footer {
  margin: 0.8rem auto 0 auto;
  width: 100%;
  max-width: 320px;
}
.SignInUpOverlay--form-footer.bigger-width {
  max-width: 390px;
}
.SignInUpOverlay--form-footer button {
  height: 72px;
  line-height: 72px;
}

.SignInUpOverlay--form-resetPassword {
  margin: 1.5rem 0 0 0;
  display: inline-block;
  cursor: pointer;
  border: none;
  text-decoration: underline;
  background: none;
}
@media (min-width: 1200px) {
  .SignInUpOverlay--form-resetPassword {
    margin: 1.5rem 0 4.5rem 0;
  }
}

.SignInUpOverlay--forgot-password {
  line-height: 2em;
  display: inline-block;
  text-decoration: underline;
  color: #4e5c63;
}

@media (min-width: 1200px) {
  .SignInUpOverlay--main {
    background: #ecebeb;
  }
}

.SignInUpOverlay--isLogin .SignInUpOverlay--main-partial {
  transform: translateX(-100%);
}
.SignInUpOverlay--isLogin .SignInUpOverlay--aside-partial {
  transform: translateX(0);
}

.SignInUpOverlay--formtitle {
  position: relative;
  margin-top: 1em;
  font-size: 16px;
  display: flex;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  font-size: 12px;
}
@media (min-width: 420px) {
  .SignInUpOverlay--formtitle {
    font-size: 16px;
  }
}
.SignInUpOverlay--formtitle:hover {
  cursor: pointer;
}
.SignInUpOverlay--formtitle.SignInUpOverlay--formtitle-isOpen .SignInUpOverlay--registerMetaInfo-toggle {
  transform: rotate(180deg);
}
@media (min-width: 1200px) {
  .SignInUpOverlay--formtitle {
    margin-bottom: 0.8rem;
  }
  .SignInUpOverlay--formtitle:hover {
    cursor: initial;
  }
}

.SignInUpOverlay--registerMetaInfo-toggle {
  background: none;
  border: none;
  position: relative;
  top: 3px;
  margin-left: 16px;
  transform-origin: 50%;
  transition: transform 0.175s ease-in-out;
}
@media (min-width: 992px) {
  .SignInUpOverlay--registerMetaInfo-toggle {
    display: none;
  }
}

.SignInUpOverlay--registerMetaInfo-toggle svg {
  width: 36px;
  height: 36px;
}

.SignInUpOverlay--formsubtitle {
  font-weight: 600;
  margin-right: 6px;
}

.SignInUpOverlay--highlight {
  color: #3aaa35;
}

.SignInUpOverlay--inv-token-line-1 {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 1rem;
  font-weight: bold;
}
.SignInUpOverlay--inv-token-line-1:first-of-type {
  margin-top: -30px;
}

.SignInUpOverlay--password-rules {
  padding: 14px 0 10px 0;
  color: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  transition: height 0.15s ease, padding 0.15s ease;
}
.SignInUpOverlay--password-rules li {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}
.SignInUpOverlay--password-rules li:last-of-type {
  margin-bottom: 0;
}
.SignInUpOverlay--password-rules svg {
  display: inline-block;
  margin-left: 8px;
  width: 15px;
  height: 15px;
}
.SignInUpOverlay--password-rules svg path {
  transition: fill 0.15s ease;
}

.SignInUpOverlay--aside,
.SignInUpOverlay--main {
  min-height: 710px;
  box-sizing: content-box;
  position: relative;
  overflow: hidden;
}
@media (min-width: 1200px) {
  .SignInUpOverlay--aside,
  .SignInUpOverlay--main {
    padding: 2rem 0;
  }
}

.SignInUpOverlay--register-main {
  padding-top: 4rem !important;
}
@media (min-width: 1200px) {
  .SignInUpOverlay--register-main {
    padding-top: 0 !important;
  }
}

.SignInUpOverlay--aside-partial,
.SignInUpOverlay--main-partial {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
}
@media (min-width: 1200px) {
  .SignInUpOverlay--aside-partial,
  .SignInUpOverlay--main-partial {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    transition: transform 0.25s ease-in-out;
  }
}

.SignInUpOverlay--aside-partial {
  transform: translateX(100%);
}
@media (min-width: 1200px) {
  .SignInUpOverlay--aside-partial {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.SignInUpOverlay--login-aside {
  left: -100%;
}

.SignInUpOverlay--login-main {
  left: 100%;
  background: #ecebeb;
  margin-top: 4rem;
  padding-top: 2rem;
  padding-bottom: 4rem;
}
@media (min-width: 1200px) {
  .SignInUpOverlay--login-main {
    margin-top: 0;
    background: none;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.SignInUpOverlay--aside-section,
.SignInUpOverlay--main-section {
  width: 100%;
}
@media (min-width: 1200px) {
  .SignInUpOverlay--aside-section,
  .SignInUpOverlay--main-section {
    text-align: center;
  }
}

.SignInUpOverlay--aside-section .FormButton {
  height: 72px;
  line-height: 72px;
}

.SignInUpOverlay--aside-headline {
  color: #4e5c63;
}

.SignInUpOverlay--desc-text {
  margin-top: -2rem;
  margin-bottom: 4rem;
}

.SignInUpOverlay--section-icon {
  width: 65px;
  height: 78px;
  margin: 0 auto 6rem auto;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
}
.SignInUpOverlay--section-icon.SignInUpOverlay--section-icon-logo {
  width: 177px;
  height: 70px;
}

.SignInUpOverlay--bm-logo {
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  width: 168px;
  height: 68px;
  margin: 0 auto 7rem auto;
}

.SignInUpOverlay--form {
  max-width: 450px;
  margin: -2rem auto 0 auto;
}
@media (min-width: 1200px) {
  .SignInUpOverlay--form input {
    background: white !important;
  }
}

.SignInUpOverlay--success {
  min-height: 370px;
  padding: 0 2rem;
  flex-direction: column;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  line-height: 28px;
}
@media (min-width: 960px) {
  .SignInUpOverlay--success {
    padding: 0 20rem;
  }
}

.SignInUpOverlay--ok-btn {
  position: relative;
  width: 100px;
  margin: 7rem auto 0 auto;
}
.SignInUpOverlay--ok-btn button {
  bottom: 0 !important;
}

.SignInUpOverlay--aside-wrapper {
  display: none;
}
@media (min-width: 1200px) {
  .SignInUpOverlay--aside-wrapper {
    display: block;
  }
}

.SignInUpOverlay--registerMetaInfo-wrapper {
  overflow: hidden;
  transition: height 0.175s ease-in-out;
}

.SignInUpOverlay--register-view-headline {
  margin-bottom: 1rem !important;
}

.SignInUpOverlay--register-view-subheadline {
  color: rgb(85, 85, 85);
  font-size: 14px;
  margin-top: -10px;
  margin-bottom: 16px;
  text-align: center;
}

