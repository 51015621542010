@import "../../shared";

.MessageOverlay {
  background: #fff;
  @media(min-width: $screen-lg-min) {
    margin: 20vh auto 0;
    background: transparent;
  }
}

.MessageOverlay--inner {
  background-color: #fff;
  text-align: center;
  padding: 1em;

  @media(min-width: $screen-lg-min) {
  }
}

.MessageOverlay--footer {
  margin: 1em;
}

