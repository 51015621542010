.CommentOverlayRatingInput {
  padding: 0em 1em 1em 1em;
}
.CommentOverlayRatingInput.CommentOverlayRating--disabled * {
  pointer-events: none !important;
}

.CommentOverlayRatingInput--star {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
  width: 40px;
  height: 40px;
  display: inline-block;
  background: url(../../../images/icon-muffin.svg) center center no-repeat;
  background-size: auto 100%;
  opacity: 0.5;
  vertical-align: top;
}

.CommentOverlayRatingInput--interactable {
  cursor: pointer;
}

.CommentOverlayRatingInput--star__filled {
  opacity: 1;
}

