.custom-select {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #904A27;
  margin-bottom: 35px;
}
.custom-select .selected-option, .custom-select .option {
  width: 100%;
  margin-bottom: 0;
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.5px;
  border: none;
  color: #904A27;
  background: none;
  padding-bottom: 10px;
  cursor: pointer;
  position: relative;
}
.custom-select .selected-option.placeholder, .custom-select .option.placeholder {
  color: #CB8C6B;
}
.custom-select .selected-option i, .custom-select .option i {
  position: absolute;
  background-image: url("../../images/activation-campaign/icons/Icon-Pulldown-Down.svg");
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center;
  height: 16px;
  width: 16px;
  display: block;
  right: 10px;
  top: 0;
}
.custom-select .selected-option.open i, .custom-select .option.open i {
  transform: rotate(180deg);
}
.custom-select .option:hover {
  background-color: #e6e6e6;
}
.custom-select .options {
  padding: 20px;
  background: #F6ECE6;
  position: absolute;
  top: 100%;
  left: 0px;
  right: 0;
  border-top: none;
  z-index: 1;
  width: 100%;
}

