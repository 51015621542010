@import "../../shared";

.RecipeAuthor {
  display: flex;
  align-items: center;
  margin: 0 auto 10px auto;
  flex-wrap: wrap;
  justify-content: center;
}

.RecipeAuthor--name {
  text-decoration: underline;
  color: $colorBrown;
}

.RecipeAuthor--von {
  color: rgba(0, 0, 0, 0.82);
  @media(min-width: $screen-lg-min) {
    width: auto;
  }
}

.RecipeAuthor--crown {
  background: url('../../images/icon-cross.svg') no-repeat 50% 50%;
}
