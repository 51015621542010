@import "../../../shared";

.ModalCloseButton {
  @include hide-text;
  position: absolute;
  top: 43px;
  right: 15px;
  width: 30px;
  height: 30px;
  display: block;
  z-index: 10000;
  background: url(../../../images/icon-close.svg) center center no-repeat;
  background-size: 100% auto;
  cursor: pointer;
}
