@import "../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/clearfix";
@import "../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/hide-text";
@import "../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";

$colorBrown: #904b27;
$colorDarkBrown:#874E2F;
$colorLightBrown: rgb(245,236,230);
$colorDarkBlue: #4e5c63;
$colorDarkBlueHover: #505f68;
$colorGreen: #3aaa35;
$colorGray: #ecebeb;
$colorRed: #c8392e;
$colorYellow: #f39c12;
$redNew: #DA2A1F;
$colorCTA: $colorRed;