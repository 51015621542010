.custom-select {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #904A27;
  margin-bottom: 35px;
  .selected-option, .option {
    width: 100%;
    margin-bottom: 0;
    font-family: Lato;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
    border: none;
    color: #904A27;
    background: none;
    padding-bottom: 10px;
    cursor: pointer;
    position: relative;    
    &.placeholder {
      color: #CB8C6B; 
    }
    i{
      position: absolute;
      background-image: url("../../images/activation-campaign/icons/Icon-Pulldown-Down.svg");
      background-repeat: no-repeat;
      background-size: 16px;
      background-position: center;
      height: 16px;
      width: 16px;
      display: block;
      right: 10px;
      top: 0;
    }
    &.open{
      i{
        transform: rotate(180deg);
      }
    }
  }

  .option:hover {
    background-color: #e6e6e6;
  }

  .options {
    padding: 20px;
    background: #F6ECE6;
    position: absolute;
    top: 100%;
    left: 0px;
    right: 0;
    border-top: none;
    z-index: 1;
    width: 100%;
  }
}
