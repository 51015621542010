.FormInput {
  height: 30px;
  line-height: 30px;
  width: 200px;
  font-size: 14px;
  padding: 0 10px;
  border: none;
  box-shadow: 3px 0px 13px rgba(0, 0, 0, 0.09);
  font-family: "Lato", sans-serif;
}
.FormInput.big-input {
  height: 42px;
  line-height: 42px;
  margin: 1.5rem 0;
}
.FormInput::placeholder {
  color: silver;
  text-transform: uppercase;
}

.FormInput[data-error=true] {
  background-color: #ffcccc !important;
}
.FormInput[data-error=true]::placeholder {
  color: red;
  text-transform: uppercase;
}

.FormInput[data-block=true] {
  width: 100%;
}

.FormInput[data-dark=true] {
  background-color: #ecebeb;
  color: #000;
}
.FormInput[data-dark=true]::placeholder {
  color: #4e5c63;
}

.FormInput[data-lowercase=true]::placeholder {
  text-transform: none;
}

.FormInput[data-noshadow=true] {
  box-shadow: none;
}

.FormInput[data-spaced] {
  margin: 0.5em 0;
}

