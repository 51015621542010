.ReactModalPortal {
  z-index: 999999;
  position:relative;
}

.ReactModal__Overlay, .ReactModal__Overlay--after-open {
  background: rgba(0, 0, 0, 0.5) !important;
  overflow: auto;
   -webkit-overflow-scrolling: touch;
}
