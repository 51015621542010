@import "../../shared";

$mobileBreakpoint: 740px;

.CommentOverlay--Modal {
  overflow: auto;
  background: #fff;
  position: relative;

  @media(min-width: 960px) {
    background: transparent;
    width: 960px;
    margin: 0 auto;
  }

  .ModalCloseButton {
    top: 15px;
    background-size: 18px auto;
    @media (min-width: 1200px) {
      top: 43px;
      background-size: 100% auto;
    }
  }

  .ButtonDistorted {
    margin-top: 1.5rem;
    @media (min-width: 700px) {
      margin-top: 0;
    }
  }
}

.CommentOverlay {
  padding-top: 4.5rem;
  padding-bottom: 10rem;
  @media (min-width: 1200px) {
    padding-top: 2em;
    padding-bottom: 2rem;
  }
}

.CommentOverlay--body {
  margin: 2em 0;
}

.CommentOverlay--footer-text {
  margin-bottom: 2rem;
}
.CommentOverlay--link {
  color: $colorBrown;
  text-decoration: underline;
  cursor: pointer;
}

.CommentOverlay--inner {
  border-radius: 14px;
  background: #fff;

  @media(min-width: $screen-lg-min) {
    padding: 2em;
  }
}

.CommentOverlay--header {
  margin-top: 1em;
}

.CommentOverlay--rating-wrapper {
  text-align: center;
}

.CommentOverlay--comments-wrapper {
  min-height: 250px;
  margin-top: 32px;
  border-top: 1px solid rgba(#000, 0.24);
  border-bottom: 1px solid rgba(#000, 0.24);

  @media (min-width: 750px) {
    height: 250px;
    overflow: auto;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;
    }
  
    &::-webkit-scrollbar {
      width: 6px;
      background-color: #F5F5F5;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #222;
      border-radius: 4px;
    }
  }
}

.CommentOverlay--no-comments {
  display: flex;
  justify-content: center;
  align-items: center;
}

.CommentOverlay--send-comment-btn {
  margin-top: 18px;
  display: flex;
  justify-content: flex-end;
}

.CommentOverlay--inner-wrapper {
  @media (min-width: $mobileBreakpoint) {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
}

.CommentOverlay--teaser-container {
  @media (min-width: $mobileBreakpoint) {
    width: 42%;
  }
  &.CommentOverlay--teaser-container_isMobile {
    display: flex;
    justify-content: space-between;
    & > article {
      width: 46%;
    }
  }
  & > article {
    height: 100%;
  }
}

.CommentOverlay--rating-container {
  @media (min-width: $mobileBreakpoint) {
    width: 54.5%;
  }
}

.CommentOverlay--inline-teaser {
  margin-bottom: 0;
  padding-bottom: 0;
  &.CommentOverlay--inline-mobile-teaser {
    width: 50%;
  }
}

.CommentOverlay--loading {
  opacity: 0.5 !important;
  * {
    pointer-events: none !important;
  }
}

.CommentOverlay--mobile-teasers-wrapper {
  width: 100%;
  height: 240px;
  display: flex;
}

.CommentOverlay--comment-input {
  position: relative;
}

.CommentOverlay--comment-input-not-logged-in {
  position: absolute;
  z-index: 111;
  top: 14px;
  left: 14px;
  button {
    background: none;
    border: none;
    color: $colorBrown;
    text-decoration: underline;
  }
}

.CommentOverlay--success-comment {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
