.FormLabel {
  margin-bottom: 5px;
  display: block;
}

.ConsentOverlay--track-provider {
  display: flex;
  align-items: center;
}

