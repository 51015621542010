.ContentBlockImage a {
  text-decoration: none;
}

.ContentBlockImage a:hover .ContentBlockImage--figcaption:before {
  transform: scale(1.075);
}

.ContentBlockImage--figcaption {
  display: flex;
  align-items: center;
  margin-top: -3px;
  padding: 6px 8px 6px 12px;
  text-align: left;
  background: #904b27;
  color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ContentBlockImage--figcaption:before {
  content: "";
  display: block;
  margin-right: 12px;
  width: 15px;
  height: 15px;
  background: url(../../images/icon-url.svg);
  background-size: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  transition: transform 0.15s ease;
}

.ContentBlockImage--figure {
  text-align: center;
}

.ContentBlockImage--placeholder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(#f4efe9 0%, #fff 100%);
  transition: opacity 0.125s ease;
}

.ContentBlockImage--img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  margin: 0;
  padding: 0;
}

