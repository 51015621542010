.MainMenu {
  width: 80%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: -100%;
  background: #fff;
  z-index: 9999999;
  overflow: auto;
  transition: right 0.2s ease-in-out;
}
@media (min-width: 1200px) {
  .MainMenu {
    position: absolute;
    box-shadow: none;
    margin-top: 2em;
    background: rgba(255, 255, 255, 0.75);
    height: 45px;
    overflow: visible;
    width: 100%;
    bottom: 0;
    left: 0;
    top: auto;
    z-index: 6;
  }
}
.MainMenu[data-open=true] {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  right: 0;
}

[data-active-page=suche] .MainMenu[data-open=true] {
  box-shadow: none;
}

.MainMenu--closebutton {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
  width: 21px;
  height: 21px;
  background: red;
  top: 17px;
  right: 17px;
  position: absolute;
  background: url(../../images/icon-cross.svg) center center no-repeat;
  background-size: contain;
}
@media (min-width: 1200px) {
  .MainMenu--closebutton {
    display: none;
  }
}

.MainMenu--header {
  height: 70px;
}
@media (min-width: 1200px) {
  .MainMenu--header {
    display: none;
  }
}

.MainMenu--main {
  padding: 0 20px 0 10px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.25em;
  height: initial;
}
@media (min-width: 1200px) {
  .MainMenu--main {
    position: relative;
    z-index: 100;
    padding: 0;
  }
  .MainMenu--main > .Layout {
    padding-top: 0.5em;
  }
}

.MainMenu--menu-item,
.MainMenu--submenu--menu-item {
  margin-bottom: 1em;
  position: relative;
}
@media (min-width: 1200px) {
  .MainMenu--menu-item,
  .MainMenu--submenu--menu-item {
    margin-bottom: auto;
    vertical-align: top;
  }
  .MainMenu--menu-item:hover,
  .MainMenu--submenu--menu-item:hover {
    display: block !important;
  }
  .MainMenu--menu-item:hover .MainMenu--submenu,
  .MainMenu--submenu--menu-item:hover .MainMenu--submenu {
    animation: fadeInDown 0.5s;
    display: block !important;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translatey(-10px);
    -moz-transform: translatey(-10px);
    -o-transform: translatey(-10px);
    transform: translatey(-10px);
  }
  10% {
    opacity: 0;
  }
  to {
    opacity: 1;
    -webkit-transform: translatey(0);
    -moz-transform: translatey(0);
    -o-transform: translatey(0);
    transform: translatey(0);
  }
}
.MainMenu--menu-item {
  font-size: 17px;
}
@media (min-width: 1200px) {
  .MainMenu--menu-item {
    padding-bottom: 10px;
  }
}
.MainMenu--menu-item.MainMenu--menu-item__is-open .MainMenu--link--caret {
  transform: rotate(0deg);
}
.MainMenu--menu-item.MainMenu--menu-item__is-open .MainMenu--submenu {
  display: block;
}

.MainMenu--submenu--menu-item {
  font-size: 14px;
  text-transform: initial;
}
@media (min-width: 1200px) {
  .MainMenu--submenu--menu-item {
    width: 100%;
    padding-bottom: 4px;
    padding-top: 4px;
    padding-left: 40px;
    padding-right: 15px;
    white-space: nowrap;
  }
}

.MainMenu--submenu {
  display: none;
  margin-top: 1em;
  margin-left: 40px;
}
@media (min-width: 1200px) {
  .MainMenu--submenu {
    display: none !important;
    position: absolute;
    background: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    margin: 8px 0 0 0;
    padding-top: 5px;
    padding-bottom: 5px;
    min-width: 200px;
  }
}
.MainMenu--menu-item__is-open .MainMenu--submenu {
  display: block;
}

.MainMenu--link {
  text-decoration: none;
  color: #904b27;
  display: inline-block;
  position: relative;
  z-index: 1;
  margin: 0 auto;
}
.MainMenu--link:hover {
  color: #c8392e;
}

@media (min-width: 1200px) {
  .MainMenu--logout-mobile {
    display: none;
  }
}
.MainMenu--logout-mobile .MainMenu--profile-image {
  background: none;
}

.MainMenu--link__active {
  color: #c8392e;
}

.MainMenu--link--caret {
  display: inline-block;
  width: 16px;
  height: 16px;
  content: "";
  background: url(../../images/icon-caret-down.svg) center center no-repeat;
  background-size: 100% auto;
  position: absolute;
  top: 2px;
  right: 0;
  transition: transform 0.2s ease-in-out;
  transform: rotate(-90deg);
}
@media (min-width: 1200px) {
  .MainMenu--link--caret {
    display: none;
  }
}

.MainMenu--menu {
  position: relative;
}
@media (min-width: 1200px) {
  .MainMenu--menu {
    display: flex;
    vertical-align: top;
    padding: 0;
    width: calc(100% - 65px);
    justify-content: space-between;
  }
}

.MainMenu--search-cta {
  display: none;
}
@media (min-width: 1200px) {
  .MainMenu--search-cta {
    position: absolute;
    top: -10px;
    right: 15px;
    display: block;
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
    width: 45px;
    height: 45px;
    background: #4e5c63 url(../../images/icon-magnifier-white.svg) no-repeat center center;
    background-size: 70% auto;
  }
}

.MainMenu--profile-image {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: silver;
  border-radius: 30px;
  margin-right: 7px;
  transform: translateY(-2px);
  overflow: hidden;
  vertical-align: middle;
}

.MainMenu--profile-image--image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.MainMenu--link--figure {
  width: 30px;
  height: 30px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  margin-top: -5px;
  margin-right: 10px;
}

.MainMenu--link--figure--image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

body[data-active-page=mein-profil] .MainMenu--notif-count,
body[data-active-page=meine-rezepte] .MainMenu--notif-count,
body[data-active-page=upload] .MainMenu--notif-count,
body[data-active-page=meine-favoriten] .MainMenu--notif-count,
body[data-active-page=meine-nachrichten] .MainMenu--notif-count {
  display: none;
}

.MainMenu--notif-count {
  font-family: Lato, sans-serif;
  position: absolute;
  top: -10px;
  left: 17px;
  width: 24px;
  height: 24px;
  background: #c8392e;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  border-radius: 50%;
}
.MainMenu--notif-count.inDropdown {
  left: initial;
  top: -6px;
  right: -24px;
}
.MainMenu--notif-count.mobile--notif-count {
  font-size: 11px;
}

