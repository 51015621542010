.UserCrownLevel--crown {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-size: 100%;
  background-position: 50%;
  background-repeat: no-repeat;

  &.UserCrownLevel--noCrown {
    display: none;
  }

  &.UserCrownLevel--bronzeCrown {
    background-image: url('../../images/krone_bronze.svg');
  }

  &.UserCrownLevel--silverCrown {
    background-image: url('../../images/krone_silber.svg');
  }

  &.UserCrownLevel--goldCrown {
    background-image: url('../../images/krone_gold.svg');
  }

}


