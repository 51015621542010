.RecipeDifficulty {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px;
  color: #3aaa35;
}

.RecipeDifficulty[data-orange=true] {
  color: orange;
}

