.Footer {
  position: relative;
  background-color: #904b27;
  color: #fff;
  text-align: center;
}
.Footer:before {
  content: "";
  position: fixed;
  right: 0;
  height: 2000px;
  left: 0;
  background: #904b27;
  z-index: -1;
}
.Footer--newsletter {
  padding-top: 30px;
}
.Footer--newsletter .Headline {
  color: #fff;
  font-size: 24px;
  padding: 0 40px;
}
.Footer .Footer--social {
  padding: 2em 0 0;
}
.Footer .Footer--social ul {
  display: inline-block;
}
.Footer .Footer--social ul li {
  display: inline-block;
  margin: 0 10px;
}
.Footer .Footer--social ul li button, .Footer .Footer--social ul li a {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
  display: block;
  width: 40px;
  height: 40px;
}
.Footer .Footer--social .Footer--social--icon--facebook {
  background: url(../../images/icon-facebook.svg) center center no-repeat;
  background-size: contain;
}
.Footer .Footer--social .Footer--social--icon--instagram {
  background: url(../../images/icon-instagram.svg) center center no-repeat;
  background-size: contain;
}
.Footer .Footer--social .Footer--social--icon--youtube {
  background: url(../../images/icon-youtube.svg) center center no-repeat;
  background-size: contain;
}
.Footer .Footer--social .Footer--social--icon--pinterest {
  background: url(../../images/icon-pinterest.png) center center no-repeat;
  background-size: contain;
}
.Footer .Footer--social .Footer--social--icon--newsletter {
  background: url(../../images/icon-mail-01.svg) center center no-repeat;
  background-size: contain;
}
.Footer .Footer--brands {
  padding: 2em 0 0;
}
.Footer .Footer--brands:before {
  content: "";
  height: 76.875px;
  display: inline-block;
  width: 100%;
  background: url(../../images/highlight-brands-footer.svg) center center no-repeat;
  background-size: auto 100%;
  margin-bottom: 0.5em;
}
.Footer .Footer--brands ul {
  display: inline-block;
}
.Footer .Footer--brands ul li {
  display: inline-block;
  margin: 0 10px;
}
.Footer .Footer--brands ul li button, .Footer .Footer--brands ul li a {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
  width: 50px;
  height: 50px;
  display: inline-block;
}
.Footer .Footer--brands img {
  width: 100%;
  height: 100%;
}
.Footer .Footer--footer-nav {
  padding: 2em 0 2em;
}
.Footer .Footer--footer-nav li {
  display: inline-block;
  padding: 0 10px;
  font-size: 20px;
  border-right: 1px solid #fff;
  font-family: "lemon-yellow";
  text-transform: uppercase;
}
.Footer .Footer--footer-nav li:hover {
  cursor: pointer;
}
.Footer .Footer--footer-nav li:last-child {
  border-right: none;
}
.Footer .Footer--footer-nav li button, .Footer .Footer--footer-nav li a {
  background: none;
  color: #fff;
  border: none;
  margin: 0;
  padding: 0;
  background: none;
  color: #fff;
  text-decoration: none;
}
.Footer .Footer--tagline {
  padding: 2em 0 2em;
}

