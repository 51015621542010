@import "../../shared";

.Headline.typography,
.Headline {
  line-height: 1.25em;
  padding: 0.25em 0;
  text-align: center;
  font-size:26px;
  text-transform: uppercase;
  font-family: 'lemon-yellow';
  color: $colorBrown;

  &.is-big-headline {
    font-size: 38px;
    padding: 0.75rem 0 3.5rem;
  }
  &.is-really-big-headline {
    font-size: 45px;
    padding: 1rem 0 3.75rem;
  }
  &.is-small-headline {
    font-size: 20px;
  }
}
