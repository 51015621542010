body[data-active-page=selbstgebacken] {
  background-color: #f6ece6;
}

.form-errors {
  margin-bottom: 20px;
  text-align: center;
}

.error {
  color: red;
  font-size: 14px;
  margin: 5px 0;
}

a {
  color: #CB8C6B;
}

.ActivationCampaign {
  background-color: #F6ECE6;
}
.ActivationCampaign h1, .ActivationCampaign h2:not(.Headline) {
  color: #DA2A1F;
  text-align: center;
  font-family: DharmaGothicPBold;
  font-size: 68px;
  font-style: normal;
  font-weight: 400;
  line-height: 76px;
  width: 1084px;
  margin: 100px auto 0;
}
@media (max-width: 1080px) {
  .ActivationCampaign h1, .ActivationCampaign h2:not(.Headline) {
    width: 100%;
    font-size: 42px;
    line-height: 48px;
    padding: 0 20px;
  }
}
.ActivationCampaign .button {
  text-transform: uppercase;
  font-family: DharmaGothicPBold;
  background-color: #DA2A1F;
  color: #fff;
  position: relative;
  border: none;
  padding: 12px 125px;
  border-radius: 30px;
  text-decoration: none;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 1080px) {
  .ActivationCampaign .button {
    padding: 12px 75px;
  }
}
.ActivationCampaign .button i {
  position: absolute;
  background-image: url("../../images/activation-campaign/icons/Icon-Download-Mini.svg");
  background-repeat: no-repeat;
  background-size: 17px;
  background-position: center;
  height: 17px;
  width: 17px;
  display: block;
  left: 20px;
  top: 20px;
}
.ActivationCampaign__hero {
  height: 600px;
}
@media (max-width: 1080px) {
  .ActivationCampaign__hero {
    height: 100%;
  }
}
@media (max-width: 1280px) {
  .ActivationCampaign__hero {
    height: 500px;
  }
}
.ActivationCampaign__hero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ActivationCampaign__description {
  padding: 50px 0 175px;
  text-align: center;
}
.ActivationCampaign__description h2 {
  margin-top: 136px;
}
.ActivationCampaign__description p {
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
  letter-spacing: 0.692px;
  color: #904A27;
  width: 711px;
  margin: 60px auto 0;
}
@media (max-width: 1080px) {
  .ActivationCampaign__description p {
    width: 100%;
    padding: 0 20px;
  }
}
.ActivationCampaign__price {
  padding: 50px 0 150px;
  background-color: white;
}
@media (max-width: 1080px) {
  .ActivationCampaign__price {
    padding: 50px 0 50px;
  }
}
.ActivationCampaign__price .item {
  display: flex;
  align-items: start; /* Align items to the top */
  margin-bottom: 20px; /* Or whatever space you want between items */
  width: 1091px;
  margin: auto;
}
@media (max-width: 1080px) {
  .ActivationCampaign__price .item {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}
.ActivationCampaign__price .content.backkiste {
  padding-top: 150px !important;
}
.ActivationCampaign__price .content.backkiste p {
  text-align: left;
  margin-bottom: 20px;
}
.ActivationCampaign__price .content.marmor {
  padding-top: 150px !important;
}
@media (max-width: 1080px) {
  .ActivationCampaign__price .content.marmor {
    padding-top: 50px !important;
  }
}
.ActivationCampaign__price .content.marmor a {
  color: #904A27;
}
.ActivationCampaign__price .content img {
  width: 100%; /* Or whatever width you want */
  display: block;
  margin-bottom: 10px; /* Space between image and text */
  padding-right: 60px;
}
@media (max-width: 1080px) {
  .ActivationCampaign__price .content img {
    padding-right: 0px;
  }
}
.ActivationCampaign__price .content img.andrea {
  margin-top: -185px;
}
.ActivationCampaign__price .content .image-text {
  text-align: center;
  padding-right: 60px;
  margin-top: 20px;
}
@media (max-width: 1080px) {
  .ActivationCampaign__price .content .image-text {
    margin-bottom: 50px;
  }
}
.ActivationCampaign__price .content p {
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.692px;
  color: #904A27;
  text-align: center;
}
.ActivationCampaign__price .content ul {
  list-style: disc;
  text-align: left;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.692px;
  color: #904A27;
  list-style-position: inside;
}
@media (max-width: 1080px) {
  .ActivationCampaign__price .content ul {
    width: 75%;
    padding: 0 20px;
    margin: 20px auto 0;
  }
}
.ActivationCampaign__price .item .content {
  width: 50%;
  padding: 0 10px; /* Give some space between text and image */
}
@media (max-width: 1080px) {
  .ActivationCampaign__price .item .content {
    width: 100%;
  }
}
.ActivationCampaign__price h2 {
  margin-top: 140px;
  margin-bottom: 40px;
}
.ActivationCampaign__price h2.marmor-h2 {
  margin-bottom: 50px;
}
.ActivationCampaign__price .subtitle {
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 144.444% */
  letter-spacing: 0.692px;
  text-align: center;
  color: #904A27;
  width: 523px;
  margin: 0 auto 45px;
}
@media (max-width: 1080px) {
  .ActivationCampaign__price .subtitle {
    width: 100%;
    padding: 0 20px;
  }
}
.ActivationCampaign__howitworks {
  padding: 50px 0 150px;
  background-color: #ECB8D4;
}
@media (max-width: 1080px) {
  .ActivationCampaign__howitworks {
    padding: 50px 0 50px;
  }
}
@media (max-width: 1080px) {
  .ActivationCampaign__howitworks h2 {
    margin-top: 0;
  }
}
.ActivationCampaign__howitworks ul {
  width: 502px;
  margin: 50px auto 20px;
  display: flex;
}
@media (max-width: 1080px) {
  .ActivationCampaign__howitworks ul {
    width: 100%;
    padding: 0 20px;
    align-items: center;
    justify-content: space-between;
  }
}
.ActivationCampaign__howitworks ul li {
  margin-right: 56px;
}
@media (max-width: 1080px) {
  .ActivationCampaign__howitworks ul li {
    margin-right: 0;
  }
}
.ActivationCampaign__howitworks ul li img {
  height: 140px;
}
@media (max-width: 1080px) {
  .ActivationCampaign__howitworks ul li img {
    height: 95px;
  }
}
.ActivationCampaign__howitworks .subtitle {
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
  letter-spacing: 0.692px;
  width: 525px;
  text-align: center;
  margin: 0 auto 90px;
  color: white;
}
@media (max-width: 1080px) {
  .ActivationCampaign__howitworks .subtitle {
    width: 100%;
    padding: 0 50px;
  }
}
.ActivationCampaign__howitworks .steps {
  display: flex;
  width: 1081px;
  margin: auto;
}
@media (max-width: 1080px) {
  .ActivationCampaign__howitworks .steps {
    width: 100%;
    display: block;
  }
}
.ActivationCampaign__howitworks .steps .step {
  width: 343px;
  text-align: center;
  margin-right: 20px;
  padding: 0 20px;
}
@media (max-width: 1080px) {
  .ActivationCampaign__howitworks .steps .step {
    width: 100%;
    margin-bottom: 50px;
  }
}
.ActivationCampaign__howitworks .steps .step img {
  width: 182px;
  height: 182px;
}
.ActivationCampaign__howitworks .steps .step p {
  margin-top: 30px;
  color: #FFF;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
  letter-spacing: 0.692px;
}
.ActivationCampaign__form {
  padding: 100px 0 150px;
  background-color: white;
}
@media (max-width: 1080px) {
  .ActivationCampaign__form {
    padding: 50px 0 50px;
  }
}
.ActivationCampaign__form h2 {
  margin-top: 0 !important;
  margin-bottom: 72px !important;
}
.ActivationCampaign__form form {
  width: 525px;
  margin: auto;
}
@media (max-width: 1080px) {
  .ActivationCampaign__form form {
    width: 100%;
    padding: 0 20px;
  }
}
.ActivationCampaign__form form .button i {
  transform: rotate(-90deg);
}
.ActivationCampaign__form form input[type=text] {
  width: 100%;
  margin-bottom: 35px;
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.5px;
  border: none;
  color: #904A27;
  border-bottom: 1px solid #904A27;
  background: none;
  padding-bottom: 10px;
}
.ActivationCampaign__form form input[type=text]::placeholder {
  color: #CB8C6B;
}
.ActivationCampaign__form form .terms-section {
  position: relative;
  margin-top: 30px;
  display: flex;
}
.ActivationCampaign__form form .terms-section input[type=checkbox] {
  display: none;
}
.ActivationCampaign__form form .terms-section input[type=checkbox] + label {
  flex-shrink: 0;
  display: inline-block;
  width: 22px;
  height: 23px;
  border: 1px solid #904A27;
  cursor: pointer;
  position: relative;
  vertical-align: middle;
  border-radius: 2px;
}
.ActivationCampaign__form form .terms-section input[type=checkbox] + label:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background-color: #904A27;
  visibility: hidden;
}
.ActivationCampaign__form form .terms-section input[type=checkbox]:checked + label:after {
  visibility: visible;
}
.ActivationCampaign__form form .checkbox-text {
  display: block;
  margin-left: 16px;
  color: #CB8C6B;
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  letter-spacing: 0.423px;
}
.ActivationCampaign__form form .mendatory {
  margin-top: 50px;
  margin-bottom: 36px;
  color: #CB8C6B;
  text-align: center;
  font-family: Lato;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 136.364% */
  letter-spacing: 0.423px;
}
.ActivationCampaign__form form .submit-button {
  text-align: center;
}
.ActivationCampaign__form form .contact {
  margin-top: 50px;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
  letter-spacing: 0.692px;
  color: #904A27;
  text-align: center;
}
.ActivationCampaign__form form .contact a {
  color: #904A27;
}
.ActivationCampaign__brands {
  padding: 100px 0 150px;
}
@media (max-width: 1080px) {
  .ActivationCampaign__brands {
    padding: 50px 0 50px;
  }
}
.ActivationCampaign__brands h2 {
  margin-top: 0 !important;
  margin-bottom: 25px !important;
}
.ActivationCampaign__brands .subtitle {
  color: var(--primary-brown, #904A27);
  text-align: center;
  /* AK-LP/Desktop/Copy */
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
  letter-spacing: 0.692px;
}
.ActivationCampaign__brands .brands {
  width: 1083px;
  margin: 76px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}
@media (max-width: 1080px) {
  .ActivationCampaign__brands .brands {
    width: 100%;
    padding: 0 20px;
    flex-wrap: wrap;
  }
}
@media (max-width: 1080px) {
  .ActivationCampaign__brands .brands .brand {
    flex-basis: 50%;
    height: 100px;
    width: 100px;
    margin-right: 0px;
    text-align: center;
    margin-bottom: 50px;
  }
}
.ActivationCampaign__brands .brands .brand img {
  width: 160px;
  height: 157px;
}
@media (max-width: 1080px) {
  .ActivationCampaign__brands .brands .brand img {
    height: 100px;
    width: 100px;
    margin-right: 0px;
  }
}
.ActivationCampaign__recipes {
  width: 80%;
  margin: auto;
  padding-bottom: 150px;
}
.ActivationCampaign__recipes p {
  text-align: center;
  margin-bottom: 100px;
}
.ActivationCampaign__recipes .variations-swiper-container {
  position: relative;
}
.ActivationCampaign__recipes .swiper-button-prev {
  left: -45px;
  border: none;
  top: 40%;
  color: #904A27;
}
.ActivationCampaign__recipes .swiper-button-next {
  right: -45px;
  border: none;
  top: 40%;
  color: #904A27;
}

