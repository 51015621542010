.CommentOverlay--comment {
  display: flex;
  background: white;
}
.CommentOverlay--comment:first-of-type {
  margin-top: 1.5rem;
}
.CommentOverlay--comment.CommentOverlay--comment--isInForum {
  padding-left: 2rem;
  padding-right: 2rem;
}
.CommentOverlay--comment.CommentOverlay--comment__indent1 .RecipeRating--commentActions {
  padding-right: 10px;
}
.CommentOverlay--comment .microlink_card {
  max-width: 400px !important;
  min-width: 400px;
  width: 400px;
}
.CommentOverlay--comment .microlink_card__media_video_wrapper {
  pointer-events: none !important;
}
.CommentOverlay--comment .microlink_card__media_video_wrapper * {
  pointer-events: none !important;
}

.CommentOverlay--comment-attachments {
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 2rem;
}
.CommentOverlay--comment-attachments > * {
  margin-right: 5px !important;
  max-height: 90px !important;
  height: 90px !important;
}
@media (min-width: 700px) {
  .CommentOverlay--comment-attachments > * {
    max-height: 135px !important;
    height: 135px !important;
  }
}

.CommentOverlay--comment__indent1 {
  margin-left: 30px;
}

.CommentOverlay--comment__indent2 {
  margin-left: 30px;
}

.CommentOverlay--comment__indent3 {
  margin-left: 30px;
}

.CommentOverlay--comment__indent4 {
  margin-left: 30px;
}

.CommentOverlay--comment__indent5 {
  margin-left: 30px;
}

.CommentOverlay--comment-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(100% - 42px);
}
@media (min-width: 720px) {
  .CommentOverlay--comment-body {
    width: calc(100% - 70px);
  }
}
.CommentOverlay--comment-body.CommentOverlay--commentHasAttachments {
  overflow-x: hidden;
}

.CommentOverlay--comment-heading {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 12px;
}

.CommentOverlay--commentEditContainer {
  display: flex;
}
.CommentOverlay--commentEditContainer .Popover {
  margin-left: -3px !important;
}
.CommentOverlay--commentEditContainer .Popover--button {
  height: 22px;
}
.CommentOverlay--commentEditContainer .Popover--button .Popover--button__isInactive,
.CommentOverlay--commentEditContainer .Popover--button .Popover--button__isActive {
  justify-content: flex-end !important;
}
.CommentOverlay--commentEditContainer .Popover--button .Popover--button__isActive span {
  right: 0;
  left: initial;
}

.RecipeRating--container {
  margin-left: 12px;
  display: block;
  text-align: left;
}

.CommentOverlay--comment-author-date {
  text-align: left;
  margin-right: 0.75em;
  flex: 1;
  white-space: pre;
  overflow: hidden;
}
@media (min-width: 600px) {
  .CommentOverlay--comment-author-date .UserCrownLevel--crown:not(.UserCrownLevel--noCrown) {
    margin-right: 6px;
    position: relative;
    top: 4px;
  }
}

.CommentOverlay--comment-date {
  color: #8d8d8d;
  margin: 0 0 0.5em 0;
}

.CommentOverlay--comment-title {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 18px;
  padding: 0 1rem 0.3rem 0;
}
@media (min-width: 600px) {
  .CommentOverlay--comment-title {
    font-size: 20px;
  }
}
.CommentOverlay--comment-title a:not(.mention) {
  text-decoration: none;
  color: inherit;
}
.CommentOverlay--comment-title:after {
  content: "";
  position: absolute;
  top: -3px;
  right: 0;
  bottom: -3px;
  width: 12px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
}

.CommentOverlay--comment-avatar {
  min-width: 30px;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}
@media (min-width: 720px) {
  .CommentOverlay--comment-avatar {
    min-width: 60px;
    width: 60px;
    height: 60px;
  }
}

.CommentOverlay--comment-text {
  margin: 1.5rem 0;
  text-align: left;
}
@media (min-width: 920px) {
  .CommentOverlay--comment-text {
    margin-top: initial;
  }
}
.CommentOverlay--comment-text a:not(.mention) {
  color: #904b27;
  text-decoration: underline;
}

.CommentOverlay--replyInput {
  padding-top: 2rem;
}
@media (min-width: 800px) {
  .CommentOverlay--replyInput {
    padding-top: 0;
    display: flex;
    align-items: center;
  }
}

.RecipeRating--imageAttachment {
  margin: 0 0 2rem 0;
  display: block;
  justify-content: flex-start;
  max-width: 400px;
  max-height: 200px;
  align-self: flex-start;
  overflow: hidden;
}
.RecipeRating--imageAttachment img {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  object-position: 50% 50%;
}

.CommentOverlay--sendUserReply {
  display: flex;
  justify-content: flex-end;
}

.CommentOverlay--sendUserReplyBtn {
  position: static;
  width: 120px;
  transform: none;
}
.CommentOverlay--sendUserReplyBtn span {
  position: relative;
}
.CommentOverlay--sendUserReplyBtn span:before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 1px;
  background: white;
  transform-origin: 50% 0;
  transform: scale(0, 1);
}
.CommentOverlay--sendUserReplyBtn.CommentOverlay--sendUserReplyBtnLoading span:before {
  animation: loadSubmitReply 1s ease-in-out infinite;
}
@keyframes loadSubmitReply {
  0% {
    transform: scale(0, 1);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

.CommentOverlay--replyInputContainer {
  overflow: hidden;
  transition: height 0.175s ease-in-out;
}

.CommentOverlay--closeCommentInput {
  position: absolute;
  top: 0;
  left: -50px;
  opacity: 0;
  transform: rotate(36deg) scale(0.2);
  pointer-events: none;
  transition: opacity 0.175s ease-in-out, transform 0.175s ease-in-out;
}
.CommentOverlay--closeCommentInput.CommentOverlay--closeCommentInput__visible {
  opacity: 1;
  transform: rotate(0deg) scale(1);
  pointer-events: auto;
}

.CommentOverlay--confirmCommentDelete {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #904b27;
  text-align: center;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}
.CommentOverlay--confirmCommentDelete.CommentOverlay--confirmCommentDelete__visible {
  opacity: 1;
  pointer-events: auto;
}
.CommentOverlay--confirmCommentDelete p {
  font-size: 13px;
  margin: 0.5rem 0 0.75rem 0;
}
.CommentOverlay--confirmCommentDelete button {
  border-right: 1rem;
  padding: 0.35rem 1rem;
  background: rgb(103.868852459, 54.0983606557, 28.131147541);
  color: white;
  border: 1px solid rgb(63.737704918, 33.1967213115, 17.262295082);
}
.CommentOverlay--confirmCommentDelete button:first-of-type {
  margin-right: 1rem;
}

.CommentOverlay--editCommentBox {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  box-sizing: border-box;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.15s ease-out;
}
.CommentOverlay--editCommentBox.CommentOverlay--editCommentBox_visible {
  opacity: 1;
  pointer-events: auto;
}

.CommentOverlay--editCommentBox_footer {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.CommentOverlay--editCommentBox_footer .ButtonDistorted {
  padding: 0.5em 1em;
}
@media (min-width: 720px) {
  .CommentOverlay--editCommentBox_footer .ButtonDistorted {
    padding: 0.5em 2em;
  }
}

.CommentOverlay--editContainerBox {
  position: relative;
  margin-top: 4rem;
}
.CommentOverlay--editContainerBox textarea {
  overflow: auto !important;
}
.CommentOverlay--editContainerBox .ModalCloseButton {
  top: -39px;
  right: 2px;
  width: 32px;
  height: 32px;
  background-size: 18px;
}

.RecipeRating--commentActions {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.CommentOverlay--commentLike {
  display: flex;
  align-items: center;
  background: none;
  border: none;
}
.CommentOverlay--commentLike:focus {
  outline: 0;
}
.CommentOverlay--commentLike.isClicked .CommentOverlay--commentLikeIcon:before {
  opacity: 0;
}
.CommentOverlay--commentLike.isClicked .CommentOverlay--commentLikeIcon:after {
  opacity: 1;
}
.CommentOverlay--commentLike .CommentOverlay--commentLikeIcon {
  position: relative;
  display: block;
  width: 18px;
  height: 18px;
  margin-left: 8px;
}
.CommentOverlay--commentLike .CommentOverlay--commentLikeIcon:before, .CommentOverlay--commentLike .CommentOverlay--commentLikeIcon:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: inherit;
  height: inherit;
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  transition: opacity 0.15s ease;
}
.CommentOverlay--commentLike .CommentOverlay--commentLikeIcon:before {
  background-image: url(../../images/thumb-icon-outline.svg);
}
.CommentOverlay--commentLike .CommentOverlay--commentLikeIcon:after {
  background-image: url(../../images/thumb-icon.svg);
  opacity: 0;
}

