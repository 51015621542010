.custom-upload-input {
  margin-bottom: 35px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding-bottom: 10px;
  border-bottom: 1px solid #904A27;
  transition: background-color 0.3s;
  width: 100%;
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #CB8C6B;
  cursor: pointer;
}

.upload-icon {
  height: 16px;
  width: 16px;
  vertical-align: middle;
  position: absolute;
  right: 10px;
  cursor: pointer;
}
label{
  cursor: pointer;
}