@import "../../shared";

.HomePage {
  overflow: hidden;
}

.HomePage--field {
  padding: 0 0 1em;
  background: $colorLightBrown;
}

.HomePage--empty {
  margin: 10em 0;
  text-align: center;
}
