.HomePage {
  overflow: hidden;
}

.HomePage--field {
  padding: 0 0 1em;
  background: rgb(245, 236, 230);
}

.HomePage--empty {
  margin: 10em 0;
  text-align: center;
}

