@import "../../../shared";

.FormButton {
  transition: all 0.2s;
  height:30px;
  line-height: 30px;
  width:150px;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0 10px;
  border: none;
  box-shadow: 3px 0px 13px rgba(0, 0, 0, 0.09);
  color: #fff;
  background-color: $colorDarkBlue;

  &.isBrown {
    background: $colorBrown !important;
    &:hover {
      background-color: lighten($colorBrown, 5) !important;
    }
  }

  &:hover {
    background-color: lighten($colorDarkBlue, 5);
  }
}

.FormButton[data-light="true"] {
  border: 1px solid $colorDarkBlue;
  background-color: #fff;
  color: $colorDarkBlue;

  &:hover {
    // color: #fff;
    // background-color: lighten($colorDarkBlue, 70);
  }
}

.FormButton[data-lowercase="true"] {
  text-transform: none;
}

.FormButton[data-block="true"] {
  display:block;
  width:100%;
}

.FormButton[data-red="true"] {
  background-color: $colorRed;

  &:hover {
    background-color: lighten($colorRed, 5);
  }
}

.FormButton[data-large="true"] {
  font-size: 15px;
  height:42px;
  line-height: 42px;
}

.FormButton[disabled] {
  cursor: not-allowed;
  opacity: 0.33;
}
