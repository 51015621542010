.RecipeRating {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}

.RecipeRating--wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.RecipeRating--count {
  margin-left: 5px;
  color: rgb(85, 85, 85);
}

.RecipeRating--star {
  width: 13px;
  height: 20px;
  display: inline-block;
  background: url(../../images/icon-muffin.svg) center center no-repeat;
  background-size: 100% auto;
  margin: 0 1px;
  @media (min-width: 600px) {
    width: 20px;
    height: 30px;
  }
}

.RecipeRating--star__disabled {
  background: url(../../images/icon-muffin-disabled.svg) center center no-repeat;
  background-size: 100% auto;
}
