.Copy {
  margin: 0.5em 0 1em;
  font-size: 16px;
}
.Copy h1, .Copy h2, .Copy h3, .Copy h5, .Copy h5, .Copy p {
  margin-bottom: 0.5em;
  line-height: 1.5em;
}
.Copy p {
  margin-bottom: 1em;
}
.Copy h1, .Copy h2, .Copy h3, .Copy h4, .Copy h5 {
  font-family: "lemon-yellow";
  font-weight: bold;
  color: #904b27;
}
.Copy h1 {
  font-size: 40px;
}
.Copy h2 {
  font-size: 35px;
}
.Copy h3 {
  font-size: 30px;
}
.Copy h4 {
  font-size: 25px;
}
.Copy h5 {
  font-size: 20px;
}
.Copy ul,
.Copy ol {
  font-size: 14px;
  line-height: 21px;
  padding-left: 1.5rem;
}
.Copy ul ul,
.Copy ol ul {
  list-style-type: circle;
}
.Copy ol {
  list-style: decimal;
}
.Copy ul {
  list-style-type: disc;
}
.Copy a {
  color: #904b27;
}

