@import "../../shared";

.MainMenu {
  width: 80%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: -100%;
  background: #fff;
  z-index: 9999999;
  overflow: auto;
  transition: right 0.2s ease-in-out;

  @media(min-width: $screen-lg-min) {
    position: absolute;
    box-shadow: none;
    margin-top: 2em;
    background: rgba(255, 255, 255, 0.75);
    height: 45px;
    overflow: visible;
    width: 100%;
    bottom: 0;
    left: 0;
    top: auto;
    z-index: 6;
  }

  &[data-open="true"] {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    right: 0;
  }
}

[data-active-page="suche"] {
  .MainMenu {
    &[data-open="true"] {
      box-shadow: none;
    }
  }
}

.MainMenu--closebutton {
  @include hide-text;
  width: 21px;
  height: 21px;
  background: red;
  top: 17px;
  right: 17px;
  position: absolute;

  background: url(../../images/icon-cross.svg) center center no-repeat;
  background-size: contain;

  @media(min-width: $screen-lg-min) {
    display: none;
  }
}

.MainMenu--header {
  height: 70px;

  @media(min-width: $screen-lg-min) {
    display: none;
  }
}

.MainMenu--main {
  padding: 0 20px 0 10px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.25em;
  height: initial;

  @media(min-width: $screen-lg-min) {
    position: relative;
    z-index: 100;
    padding: 0;

    // height: 46px;
    > .Layout {
      // padding: 0.5em 0;
      padding-top: 0.5em;
    }
  }
}

.MainMenu--menu-item,
.MainMenu--submenu--menu-item {
  margin-bottom: 1em;
  position: relative;


  @media(min-width: $screen-lg-min) {
    margin-bottom: auto;
    vertical-align: top;

    &:hover {
      display: block !important;
      .MainMenu--submenu {
        animation: fadeInDown 0.5s;
        display: block !important;
      }
    }
  }
}


@keyframes fadeInDown {
  from {
    opacity:0;
    -webkit-transform: translatey(-10px);
    -moz-transform: translatey(-10px);
    -o-transform: translatey(-10px);
    transform: translatey(-10px);
  }
  10% {
    opacity:0;
  }
  to {
    opacity:1;
    -webkit-transform: translatey(0);
    -moz-transform: translatey(0);
    -o-transform: translatey(0);
    transform: translatey(0);
  }
}

.MainMenu--menu-item {
  font-size: 17px;

  @media(min-width: $screen-lg-min) {
    padding-bottom: 10px;
  }

  &.MainMenu--menu-item__is-open {
    .MainMenu--link--caret {
      transform: rotate(0deg);
    }
    .MainMenu--submenu {
      display: block;
    }
  }
}

.MainMenu--submenu--menu-item {
  font-size: 14px;
  text-transform: initial;
  @media(min-width: $screen-lg-min) {
    width: 100%;
    padding-bottom: 4px;
    padding-top: 4px;
    padding-left: 40px;
    padding-right: 15px;
    white-space: nowrap;
  }
}

.MainMenu--submenu {
  display: none;
  margin-top: 1em;
  margin-left: 40px;

  @media(min-width: $screen-lg-min) {
    display: none !important;
    position: absolute;
    background: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    margin: 8px 0 0 0;
    padding-top: 5px;
    padding-bottom: 5px;
    min-width: 200px;
  }

  .MainMenu--menu-item__is-open & {
    display: block;
  }
}

.MainMenu--link {
  text-decoration: none;
  color: $colorBrown;
  // padding-left: 30px;
  display: inline-block;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  &:hover {
    color: $colorRed;
  }
}

.MainMenu--logout-mobile {
  @media(min-width: $screen-lg-min) {
    display: none;
  }
  .MainMenu--profile-image {
    background: none;
  }
}

.MainMenu--link__active {
  color: $colorRed;
}

.MainMenu--link--caret {
  display: inline-block;
  width: 16px;
  height: 16px;
  content: '';
  background: url(../../images/icon-caret-down.svg) center center no-repeat;
  background-size: 100% auto;
  position: absolute;
  top: 2px;
  right: 0;
  transition: transform 0.2s ease-in-out;
  transform: rotate(-90deg);

  @media(min-width: $screen-lg-min) {
    display: none;
  }
}

.MainMenu--menu {
  position: relative;

  @media(min-width: $screen-lg-min) {
    display: flex;
    vertical-align: top;
    padding: 0;
    width: calc(100% - 65px);
    justify-content: space-between;

    & > .MainMenu--menu-item:nth-of-type(4) {
    }
  }
}

.MainMenu--search-cta {
  display: none;

  @media(min-width: $screen-lg-min) {
    position: absolute;
    top: -10px;
    right: 15px;
    display: block;
    @include hide-text;
    width: 45px;
    height: 45px;
    background: $colorDarkBlue url(../../images/icon-magnifier-white.svg) no-repeat center center;
    background-size: 70% auto;
  }
}

.MainMenu--profile-image {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: silver;
  border-radius: 30px;
  margin-right: 7px;
  transform: translateY(-2px);
  overflow: hidden;
  vertical-align: middle;
}

.MainMenu--profile-image--image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.MainMenu--link--figure {
  width: 30px;
  height: 30px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  margin-top: -5px;
  margin-right: 10px;
}

.MainMenu--link--figure--image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

body[data-active-page="mein-profil"],
body[data-active-page="meine-rezepte"],
body[data-active-page="upload"],
body[data-active-page="meine-favoriten"],
body[data-active-page="meine-nachrichten"] {
  .MainMenu--notif-count {
    display: none;
  }
}

.MainMenu--notif-count {
  font-family: Lato, sans-serif;
  position: absolute;
  top: -10px;
  left: 17px;
  width: 24px;
  height: 24px;
  background: $colorRed;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  border-radius: 50%;
  &.inDropdown {
    left: initial;
    top: -6px;
    right: -24px;
  }
  &.mobile--notif-count {
    font-size: 11px;
  }
}
