@import "../../shared";

.HeartLike--likes {
  position: relative;
  width: 100%;
  height: 100%;
  background: url(../../images/icon-heart.svg) center 75% no-repeat;
  background-size: 100% auto;
  line-height: 20px;
  font-size: 12px;
  appearance: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;

  &:before {
    content: '';
    font-size: 21px;
    position: absolute;
    color: $colorRed;
    top: 0;
    width: 30px;
    height: 30px;
    opacity: 0;
    background: url(../../images/icon-heart-red.svg) center 75% no-repeat;
  }

  &:before {
    left: 0;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 10px;
    background: rgba(white, 0.2);
    transform: translateX(-11px) skewX(-10deg);
  }

  @keyframes loadHeart {
    0% {
      transform: translateX(-11px) skewX(-10deg);
    }
    100% {
      transform: translateX(33px) skewX(-4deg);
    }
  }

  &:hover {
    background: url(../../images/icon-heart-red-outline.svg) center 75% no-repeat;
  }

  @keyframes likeAnim {
    0% {
      transform: translate3d(0, 0, 0);
      opacity: 0;
    }
    33% {
      transform: translate3d(10px, -20px, 0);
      opacity: 1;
    }
    66% {
      transform: translate3d(-10px, -40px, 0);
      opacity: 1;
    }
    100% {
      transform: translate3d(10px, -60px, 0);
      opacity: 0;
    }
  }
}

.HeartLike--likes__active {
  background: url(../../images/icon-heart-red.svg) center 75% no-repeat;
  color: #fff;
  &.HeartLike--likes__animated {
    &:before {
      animation: likeAnim 1s linear forwards;
    }
  }
  &:hover {
    background: url(../../images/icon-heart-red.svg) center 75% no-repeat;
  }
}

.HeartLike--grayBackground {
  background: url(../../images/icon-heart-gray.svg) center 75% no-repeat;
  color: white;
  &:hover {
    background: url(../../images/icon-heart-gray.svg) center 75% no-repeat;
  }
}


.HeartLike--whiteBackground {
  background: url(../../images/icon-heart-white-bg.svg) center 75% no-repeat;
  color: $colorBrown;
  &:hover {
    background: url(../../images/icon-heart-white-bg.svg) center 75% no-repeat;
  }
}

.HeartLike--currently-syncing {
  cursor: wait;
  &:after {
    animation: loadHeart 0.75s infinite ease-in-out;
  }
}
