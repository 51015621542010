.RecipeAuthor {
  display: flex;
  align-items: center;
  margin: 0 auto 10px auto;
  flex-wrap: wrap;
  justify-content: center;
}

.RecipeAuthor--name {
  text-decoration: underline;
  color: #904b27;
}

.RecipeAuthor--von {
  color: rgba(0, 0, 0, 0.82);
}
@media (min-width: 1200px) {
  .RecipeAuthor--von {
    width: auto;
  }
}

.RecipeAuthor--crown {
  background: url("../../images/icon-cross.svg") no-repeat 50% 50%;
}

