@import "../../shared";

.Footer {
  position: relative;
  background-color: $colorBrown;
  color: #fff;
  text-align: center;

  &:before {
    content: '';
    position: fixed;
    right: 0;
    height: 2000px;
    left: 0;
    background: $colorBrown;
    z-index: -1;
  }

  &--newsletter {
    padding-top: 30px;

    .Headline {
      color: #fff;
      font-size: 24px;
      padding: 0 40px;
    }
  }

  .Footer--social {
    padding:2em 0 0;

    ul {
      display:inline-block;

      li {
        display:inline-block;
        margin:0 10px;

        button, a {
          @include hide-text;
          display:block;
          width:40px;
          height:40px;
        }
      }
    }

    .Footer--social--icon--facebook {
      background: url(../../images/icon-facebook.svg) center center no-repeat;
      background-size: contain;
    }

    .Footer--social--icon--instagram {
      background: url(../../images/icon-instagram.svg) center center no-repeat;
      background-size: contain;
    }

    .Footer--social--icon--youtube {
      background: url(../../images/icon-youtube.svg) center center no-repeat;
      background-size: contain;
    }

    .Footer--social--icon--pinterest {
      background: url(../../images/icon-pinterest.png) center center no-repeat;
      background-size: contain;
    }

    .Footer--social--icon--newsletter {
      background: url(../../images/icon-mail-01.svg) center center no-repeat;
      background-size: contain;
    }
  }

  .Footer--brands {
    padding:2em 0 0;

    &:before {
      content: '';
      height:(615px/8);
      display:inline-block;
      width:100%;

      background: url(../../images/highlight-brands-footer.svg) center center no-repeat;
      background-size: auto 100%;
      margin-bottom: 0.5em;
    }

    ul {
      display:inline-block;

      li {
        display:inline-block;
        margin:0 10px;

        button, a {
          @include hide-text;
          width:50px;
          height:50px;
          display:inline-block;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;

    }
  }

  .Footer--footer-nav {
    padding:2em 0 2em;

    li {
      display:inline-block;
      padding:0 10px;
      font-size: 20px;
      border-right: 1px solid #fff;
      font-family: 'lemon-yellow';
      text-transform: uppercase;

      &:hover {
        cursor: pointer;
      }

      &:last-child {
        border-right: none;
      }

      button, a {
        background: none;
        color: #fff;
        border:none;
        margin:0;
        padding:0;
        background: none;
        color: #fff;
        text-decoration: none;
      }
    }
  }

  .Footer--tagline {
    padding:2em 0 2em;
  }
}
