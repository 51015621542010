@import "../../shared";

.Copy {
  margin: 0.5em 0 1em;
  font-size: 16px;

  h1, h2, h3, h5, h5, p {
    margin-bottom: 0.5em;
    line-height: 1.5em;
  }

  p {
    margin-bottom: 1em;
  }

  h1, h2, h3, h4, h5 {
    font-family: 'lemon-yellow';
    font-weight: bold;
    color: $colorBrown;

  }

  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 35px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 25px;
  }

  h5 {
    font-size: 20px;
  }

  ul,
  ol {
    font-size: 14px;
    line-height: 21px;
    padding-left: 1.5rem;
    ul {
      list-style-type: circle;
    }
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style-type: disc;
  }

  a {
    color: $colorBrown;
  }
}
