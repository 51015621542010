@import "../../shared";

body[data-active-page=selbstgebacken] {
  background-color: #f6ece6;
}

.ActivationCampaignEnds{
  background-color: #F6ECE6;
  h1, h2{
    color: #DA2A1F;
    text-align: center;
    font-family: DharmaGothicPBold;
    font-size: 68px;
    font-style: normal;
    font-weight: 400;
    line-height: 76px; 
    width: 1084px;
    margin: 0px auto 0;
    @media (max-width: 1080px) {
      width: 100%;
      font-size: 42px;
      line-height: 48px; 
      padding: 0 20px;
    }
  }
  .button{
    text-transform: uppercase;
    font-family: DharmaGothicPBold;    
    background-color: $redNew;
    color: #fff;
    position: relative;
    border: none;
    padding: 12px 125px;
    border-radius: 30px;
    text-decoration: none;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    i{
      position: absolute;
      background-image: url("../../images/activation-campaign/icons/Icon-Download-Mini.svg");
      background-repeat: no-repeat;
      background-size: 17px;
      background-position: center;
      height: 17px;
      width: 17px;
      display: block;
      left: 20px;
      top: 20px;
    }
  }

  &__hero{
    height: 600px;
    @media (max-width: 1080px) {
      height: 900px;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__description{
    padding: 100px 0 50px;
    text-align: center;
    
    h2{
      margin-top: 60px;
    }
    p{
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 144.444% */
      letter-spacing: 0.692px;
      color: #904A27;
      width: 711px;
      margin: 60px auto 0;
      a{
        color: #904A27;
      }
      @media (max-width: 1080px) {
        width: 100%;
        padding: 0 20px;
      }
    }
    img{
      width: 152px;
      margin-top: 60px;
    }
  }


}