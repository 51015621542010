@import "../../shared";

.RecipeDifficulty {
  display:inline-block;
  vertical-align: middle;
  margin: 0 10px;
  color: $colorGreen;
}

.RecipeDifficulty[data-orange="true"] {
  color: orange;
}
