@import "../../shared";

body[data-active-page=selbstgebacken] {
  background-color: #f6ece6;
}
.form-errors {
  margin-bottom: 20px;
  text-align: center;
}

.error {
  color: red;
  font-size: 14px;
  margin: 5px 0;
}
a{
  color: #CB8C6B;
}
.ActivationCampaign{
  background-color: #F6ECE6;
  h1, h2:not(.Headline){
    color: #DA2A1F;
    text-align: center;
    font-family: DharmaGothicPBold;
    font-size: 68px;
    font-style: normal;
    font-weight: 400;
    line-height: 76px; 
    width: 1084px;
    margin: 100px auto 0;
    @media (max-width: 1080px) {
      width: 100%;
      font-size: 42px;
      line-height: 48px; 
      padding: 0 20px;
    }
  }
  .button{
    text-transform: uppercase;
    font-family: DharmaGothicPBold;    
    background-color: $redNew;
    color: #fff;
    position: relative;
    border: none;
    padding: 12px 125px;
    border-radius: 30px;
    text-decoration: none;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 1080px) {
      padding: 12px 75px;
    }
    i{
      position: absolute;
      background-image: url("../../images/activation-campaign/icons/Icon-Download-Mini.svg");
      background-repeat: no-repeat;
      background-size: 17px;
      background-position: center;
      height: 17px;
      width: 17px;
      display: block;
      left: 20px;
      top: 20px;
    }
  }

  &__hero{
    height: 600px;
    @media (max-width: 1080px) {
      height: 100%;
    }
    @media (max-width: 1280px) {
      height: 500px;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__description{
    padding: 50px 0 175px;
    text-align: center;
    
    h2{
      margin-top: 136px;
    }
    p{
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 144.444% */
      letter-spacing: 0.692px;
      color: #904A27;
      width: 711px;
      margin: 60px auto 0;
      @media (max-width: 1080px) {
        width: 100%;
        padding: 0 20px;
      }
    }
  }
  &__price{
    padding: 50px 0 150px;
    background-color: white;
    @media (max-width: 1080px) {
      padding: 50px 0 50px;
    }
    .item {
      display: flex;
      align-items: start; /* Align items to the top */
      margin-bottom: 20px; /* Or whatever space you want between items */
      width: 1091px;
      margin: auto;
      @media (max-width: 1080px) {
        width: 100%;
        display: block;
        margin-bottom: 20px;
      }
    }
  
  .content {
    &.backkiste{
      padding-top: 150px !important;
      p{
        text-align: left;
        margin-bottom: 20px;
      }
    }
    &.marmor{
      padding-top: 150px !important;
      @media (max-width: 1080px) {
        padding-top: 50px !important;
      }
      a{
        color: #904A27;
      }
    }
    img {
      width: 100%; /* Or whatever width you want */
      display: block;
      margin-bottom: 10px; /* Space between image and text */
      padding-right: 60px;
      @media (max-width: 1080px) {
        padding-right: 0px;
      }
      &.andrea{
        margin-top: -185px;
      }
    }
    .image-text{
      text-align: center;
      padding-right: 60px;
      margin-top: 20px;
      @media (max-width: 1080px) {
        margin-bottom: 50px;
      }
    }
    p{
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.692px;
      color: #904A27;
      text-align: center;
    }
    ul{
      list-style: disc;
      text-align: left;
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.692px;
      color: #904A27;
      list-style-position: inside;
      @media (max-width: 1080px) {
        width: 75%;
        padding: 0 20px;
        margin: 20px auto 0;
      }
    }
  }
  
  
  .item .content {
      width: 50%;
      padding: 0 10px; /* Give some space between text and image */
      @media (max-width: 1080px) {
        width: 100%;
      }
  }

  h2{
   margin-top: 140px;
   margin-bottom: 40px;
   &.marmor-h2{
    margin-bottom: 50px;
   }
  }
  
  .subtitle{
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 144.444% */
    letter-spacing: 0.692px;
    text-align: center;
    color: #904A27;
    width: 523px;
    margin: 0 auto 45px;
    @media (max-width: 1080px) {
      width: 100%;
      padding: 0 20px;
    }
  }
}

&__howitworks{
  padding: 50px 0 150px;
  background-color:#ECB8D4;
  @media (max-width: 1080px) {
    padding: 50px 0 50px;
  }
  h2{
    @media (max-width: 1080px) {
      margin-top: 0;
    }
  }
  ul{
    width: 502px;
    margin: 50px auto 20px;
    display: flex;
    @media (max-width: 1080px) {
      width: 100%;
      padding: 0 20px;
      align-items: center;
      justify-content: space-between;
    }
    li{
      margin-right: 56px;
      @media (max-width: 1080px) {
        margin-right: 0;
      }
      img{
        height: 140px;
        @media (max-width: 1080px) {
          height: 95px;
        }
      }
    }
  }
  .subtitle{
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 144.444% */
    letter-spacing: 0.692px;
    width: 525px;
    text-align: center;
    margin: 0 auto 90px;
    color: white;
    @media (max-width: 1080px) {
      width: 100%;
      padding: 0 50px;
    }
  }
  .steps{
    display: flex;
    width: 1081px;
    margin: auto;
    @media (max-width: 1080px) {
      width: 100%;
      display: block;
    }
    .step{
      width: 343px;
      text-align: center;
      margin-right: 20px;
      padding: 0 20px;
      @media (max-width: 1080px) {
        width: 100%;
        margin-bottom: 50px;
      }
      img{
        width: 182px;
        height: 182px;
      }
      p{
        margin-top: 30px;
        color: #FFF;        
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 144.444% */
        letter-spacing: 0.692px;
      }
    }
  }
}
&__form{
  padding: 100px 0 150px;
  background-color: white;
  @media (max-width: 1080px) {
    padding: 50px 0 50px;
  }
  h2{
    margin-top: 0 !important;
    margin-bottom: 72px !important;
  }
  form{
    width: 525px;
    margin: auto;
    @media (max-width: 1080px) {
      width: 100%;
      padding: 0 20px;
    }
    .button{
      i{
        transform: rotate(-90deg);
      }
    }
    input[type="text"]{
      width: 100%;
      margin-bottom: 35px;
      font-family: Lato;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.5px;
      border: none;
      color: #904A27;
      border-bottom: 1px solid #904A27;
      background: none;
      padding-bottom: 10px;
      &::placeholder {
        color: #CB8C6B;  
      }
    }
    .terms-section{
      position: relative;
      margin-top: 30px;
      display: flex;
      input[type="checkbox"] {
        display: none; // Hide the default checkbox
    
        // Styling the label as the custom checkbox
        & + label {
          flex-shrink: 0;
          display: inline-block;
          width: 22px; // Set the desired width
          height: 23px; // Set the desired height
          border: 1px solid #904A27; // Set custom border color and width
          cursor: pointer;
          position: relative;
          vertical-align: middle;
          border-radius: 2px;
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 12px;
            height: 12px;
            background-color: #904A27;
            visibility: hidden; // Hide the check mark by default
          }
        }
        
        // Show the check mark when checkbox is checked
        &:checked + label:after {
          visibility: visible;
        }
      }
    }
    .checkbox-text {
      display: block;
      margin-left: 16px;
      color: #CB8C6B;
      font-family: Lato;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 138.462% */
      letter-spacing: 0.423px;
    }
    .mendatory{
      margin-top: 50px;
      margin-bottom: 36px;
      color: #CB8C6B;
      text-align: center;
      font-family: Lato;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px; /* 136.364% */
      letter-spacing: 0.423px;
    }
    .submit-button{
      text-align: center;
    }
    .contact{
      margin-top: 50px;
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 144.444% */
      letter-spacing: 0.692px;
      color: #904A27;
      text-align: center;
      a{
        color: #904A27;
      }
    }
  }
}

&__brands{
  padding: 100px 0 150px;
  @media (max-width: 1080px) {
    padding: 50px 0 50px;
  }
  h2{
    margin-top: 0 !important;
    margin-bottom: 25px !important;
  }
  .subtitle{
    color: var(--primary-brown, #904A27);
    text-align: center;
    /* AK-LP/Desktop/Copy */
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 144.444% */
    letter-spacing: 0.692px;
  }
  .brands{
    width: 1083px;
    margin: 76px auto 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    @media (max-width: 1080px) {
      width: 100%;
      padding: 0 20px;
      flex-wrap: wrap; 
    }
    .brand{
      @media (max-width: 1080px) {
        flex-basis:  50%;
        height: 100px;
        width: 100px;
        margin-right: 0px;
        text-align: center;
        margin-bottom: 50px;
      }
      img{
        width: 160px;
        height: 157px;
        @media (max-width: 1080px) {
          height: 100px;
          width: 100px;
          margin-right: 0px;
        }
      }
    }
    
  }
}

&__recipes{
  width: 80%;
  margin: auto;
  padding-bottom: 150px;
  p{
    text-align: center;
    margin-bottom: 100px;
  }
  .variations-swiper-container{
    position: relative;
  }
  .swiper-button-prev{
    left: -45px;
    border: none;
    top: 40%;
    color: #904A27;
  }
  .swiper-button-next{
    right: -45px;
    border: none;
    top: 40%;
    color: #904A27;
  }
}
}