* {
  -webkit-tap-highlight-color: transparent;
}

html {
  overflow-x: hidden;
}

.dont-break-out {
  word-break: inherit;
  hyphens: auto;
}

@media print {
  @page {
    size: A4;
    margin: 2mm;
  }
  body {
    -webkit-print-color-adjust: exact !important;
  }
  .Header,
  .Footer {
    display: none;
  }
}
.teaser--headline {
  line-height: 1.25em;
  margin: 0.25em 0;
  text-align: center;
  font-size: 26px;
  text-transform: uppercase;
  font-family: "lemon-yellow", sans-serif;
  color: #904b27;
}

.threads-list-row {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.threads-column {
  width: 100%;
}
@media (min-width: 820px) {
  .threads-column {
    width: 85%;
  }
}

.threads-new {
  position: absolute;
  top: 0;
  left: -9999px;
  width: 12%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
@media (min-width: 820px) {
  .threads-new {
    position: static;
  }
}

.text-center {
  text-align: center;
}

.App_go-back-to-top {
  z-index: 1;
  position: fixed;
  bottom: 100px;
  right: 0;
  width: 70px;
  height: 42px;
  background: #c8392e;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.125s ease;
  cursor: pointer;
}
.App_go-back-to-top.visible {
  pointer-events: auto;
  opacity: 1;
}
@media print {
  .App_go-back-to-top {
    display: none;
  }
}
.App_go-back-to-top:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scaleY(-1);
  background-position: 50%;
  background-size: 50%;
  background-repeat: no-repeat;
  background-image: url("./images/icon-scroll-back-to-top-white.svg");
}

.link-unstyled {
  text-decoration: inherit;
  color: inherit;
  color: inherit;
}

