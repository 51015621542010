@charset "UTF-8";
.Dropzone {
  display: block;
  width: 200px;
  height: 150px;
  position: relative;
  margin: 0 auto;
  background-color: #eee;
  background-size: cover;
}
.Dropzone:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -16px 0 0 -16px;
  width: 32px;
  height: 32px;
  background-image: url(../../images/icon-close.svg);
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 50%;
  transform: rotate(45deg);
  transition: transform 0.175s ease-in-out;
}
.Dropzone:after {
  content: "Bild hinzufügen";
  position: absolute;
  bottom: 17px;
  font-family: Lato, sans-serif;
  color: #4e5c63;
  text-align: center;
  width: 100%;
  left: 0;
}
.Dropzone.Dropzone--success:before, .Dropzone.Dropzone--success:after {
  display: none;
}
.Dropzone:hover {
  cursor: pointer;
}
.Dropzone:hover:before {
  transform: rotate(135deg);
}
.Dropzone.Dropzone--disabled {
  opacity: 0.72;
  cursor: not-allowed;
}
.Dropzone.Dropzone--inline {
  background-color: transparent;
  padding: 5px;
  width: auto;
  height: 42px;
  display: flex;
  align-items: center;
}
.Dropzone.Dropzone--inline.Dropzone--success {
  background-size: auto 100%;
  background-repeat: no-repeat;
}
.Dropzone.Dropzone--inline.Dropzone--success:before, .Dropzone.Dropzone--inline.Dropzone--success:after {
  display: none;
}
.Dropzone.Dropzone--inline.Dropzone--success .Dropzone__closeImage {
  top: 9px;
  right: 9px;
}
.Dropzone.Dropzone--inline:before {
  display: block;
  left: 29px;
  width: 24px;
  height: 24px;
  margin: 0 10px 0 0;
  background-size: 16px;
  position: static;
}
.Dropzone.Dropzone--inline:after {
  position: static;
  text-align: left;
}

.Dropzone__closeImage {
  width: 24px;
  height: 24px;
  position: absolute;
  top: -12px;
  right: -12px;
  background: #904b27 url(../../images/icon-close-white.svg) 50%/50% no-repeat;
  border: none;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.125s ease-in, transform 0.125s ease-in;
}
.Dropzone__closeImage:hover {
  transform: rotate(180deg);
}
.Dropzone__closeImage.Dropzone__closeImage-visible {
  opacity: 1;
  pointer-events: auto;
}

