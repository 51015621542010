.RecipeTeaser {
  position: relative;
  transition: all 0.2s;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-bottom: 1em;
  margin-bottom: 2em;
  cursor: pointer;
}
.RecipeTeaser a {
  text-decoration: none;
}
.RecipeTeaser.RecipeTeaser--hoverable:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}

.RecipeTeaser--authorLink {
  text-align: center;
  color: #904b27;
}

.RecipeTeaser--figure {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.RecipeTeaser--figure:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 42px;
  z-index: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
}

.RecipeTeaser--figure--image {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.RecipeTeaser--figure--image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.RecipeTeaser--category {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  background: #904b27 url(../../images/icon-recipe.svg) no-repeat center center;
  background-size: 80% auto;
}

.RecipeTeaser--main {
  padding: 0 0.5em;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.RecipeTeaser--duration--difficulty {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  align-items: center;
}

.RecipeTeaser--headline {
  line-height: 1.25em;
  margin: 0.25em 0 11px 0;
  text-align: center;
  font-size: 26px;
  text-transform: uppercase;
  font-family: "lemon-yellow", sans-serif;
  color: #904b27;
}

.ResipeTeaser--heart-container {
  position: absolute;
  bottom: 8px;
  left: 8px;
  z-index: 1;
  width: 24px;
  height: 24px;
}

.RecipeTeaser--rating-wrapper {
  display: flex;
  justify-content: center;
}

