@import "../../../shared";

.FormInput {
  height:30px;
  line-height: 30px;
  width:200px;
  font-size: 14px;;
  padding: 0 10px;
  border: none;
  box-shadow: 3px 0px 13px rgba(0, 0, 0, 0.09);
  font-family: 'Lato', sans-serif;

  &.big-input {
    height: 42px;
    line-height: 42px;
    margin: 1.5rem 0;
  }

  &::placeholder {
    color: silver;
    text-transform: uppercase;
  }
}

.FormInput[data-error="true"] {
  background-color: lighten(red, 40) !important;

  &::placeholder {
    color: red;
    text-transform: uppercase;
  }
}

.FormInput[data-block="true"] {
  width:100%;
}


.FormInput[data-dark="true"] {
  background-color: #ecebeb;
  color: #000;

  &::placeholder {
    color: $colorDarkBlue;
  }
}

.FormInput[data-lowercase="true"] {
  &::placeholder {
    text-transform: none;
  }
}

.FormInput[data-noshadow="true"] {
  box-shadow:none;
}

.FormInput[data-spaced] {
  margin: 0.5em 0;
}
