@import "../../shared";

.CommentOverlay--comment {
  display: flex;
  background: white;
  // padding: 1.25em 0;
  &:first-of-type {
    margin-top: 1.5rem;
  }
  &.CommentOverlay--comment--isInForum {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  &.CommentOverlay--comment__indent1 {
    .RecipeRating--commentActions {
      padding-right: 10px;
    }
  }
  .microlink_card {
    max-width: 400px !important;
    min-width: 400px;
    width: 400px;
  }
  .microlink_card__media_video_wrapper {
    pointer-events: none !important;
    & * {
      pointer-events: none !important;
    }
  }
}

// .CommentOverlay--comment:last-child {}

.CommentOverlay--comment-attachments {
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 2rem;
  > * {
    margin-right: 5px !important;
    max-height: 90px !important;
    height: 90px !important;
    @media (min-width: 700px) {
      max-height: 135px !important;
      height: 135px !important;
    }
  }
}

.CommentOverlay--comment__indent1 {
  margin-left: 30px;
}
.CommentOverlay--comment__indent2 {
  margin-left: 30px;
}
.CommentOverlay--comment__indent3 {
  margin-left: 30px;
}
.CommentOverlay--comment__indent4 {
  margin-left: 30px;
}
.CommentOverlay--comment__indent5 {
  margin-left: 30px;
}

.CommentOverlay--comment-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(100% - 42px);
  @media (min-width: 720px) {
    width: calc(100% - 70px);
  }
  &.CommentOverlay--commentHasAttachments {
    overflow-x: hidden;
  }
}

.CommentOverlay--comment-heading {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 12px;
}

.CommentOverlay--commentEditContainer {
  display: flex;
  .Popover {
    margin-left: -3px !important;
  }
  .Popover--button {
    height: 22px;
  }
  .Popover--button .Popover--button__isInactive,
  .Popover--button .Popover--button__isActive {
    justify-content: flex-end !important;
  }
  .Popover--button .Popover--button__isActive span {
    right: 0;
    left: initial;
  }
}

.RecipeRating--container {
  // padding: 8px 0;
  margin-left: 12px;
  display: block;
  text-align: left;
}

.CommentOverlay--comment-author-date {
  text-align: left;
  margin-right: 0.75em;
  flex: 1;
  white-space: pre;
  overflow: hidden;
  @media (min-width: 600px) {
    // display: flex;
    // align-items: baseline;
    .UserCrownLevel--crown:not(.UserCrownLevel--noCrown) {
      margin-right: 6px;
      position: relative;
      top: 4px;
    }
  }
}

.CommentOverlay--comment-date {
  color: #8d8d8d;
  margin: 0 0 0.5em 0;
}
.CommentOverlay--comment-title {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 18px;
  padding: 0 1rem 0.3rem 0;
  @media (min-width: 600px) {
    font-size: 20px;
  }
  a:not(.mention) {
    text-decoration: none;
    color: inherit;
  }
  &:after {
    content: '';
    position: absolute;
    top: -3px;
    right: 0;
    bottom: -3px;
    width: 12px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  }
}

.CommentOverlay--comment-avatar {
  min-width: 30px;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;

  @media (min-width: 720px) {
    min-width: 60px;
    width: 60px;
    height: 60px;
  }
}

.CommentOverlay--comment-text {
  margin: 1.5rem 0;
  text-align: left;
  @media (min-width: 920px) {
    margin-top: initial;
  }
  a:not(.mention) {
    color: $colorBrown;
    text-decoration: underline;
  }
}

.CommentOverlay--replyInput {
  padding-top: 2rem;
  @media (min-width: 800px) {
    padding-top: 0;
    display: flex;
    align-items: center;
  }
}

.RecipeRating--imageAttachment {
  margin: 0 0 2rem 0;
  display: block;
  justify-content: flex-start;
  max-width: 400px;
  max-height: 200px;
  align-self: flex-start;
  overflow: hidden;
  img {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    object-position: 50% 50%;
  }
  // @media (min-width: 800px) {
  //   max-width: 60%;
  // }
}

.CommentOverlay--sendUserReply {
  display: flex;
  justify-content: flex-end;
}

.CommentOverlay--sendUserReplyBtn {
  position: static;
  width: 120px;
  transform: none;
  span {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      right: 0;
      height: 1px;
      background: white;
      transform-origin: 50% 0;
      transform: scale(0, 1);
    }
  }
  &.CommentOverlay--sendUserReplyBtnLoading {
    span {
      &:before {
        animation: loadSubmitReply 1s ease-in-out infinite;
      }
    }
  }
  @keyframes loadSubmitReply {
    0%   { transform: scale(0, 1); opacity: 1; }
    100% { transform: scale(1, 1); opacity: 0; }
  }
}

.CommentOverlay--replyInputContainer {
  overflow: hidden;
  transition: height 0.175s ease-in-out;
}

.CommentOverlay--closeCommentInput {
  position: absolute;
  top: 0;
  left: -50px;

  opacity: 0;
  transform: rotate(36deg) scale(0.2);
  pointer-events: none;

  transition: opacity 0.175s ease-in-out,
              transform 0.175s ease-in-out;

  &.CommentOverlay--closeCommentInput__visible {
    opacity: 1;
    transform: rotate(0deg) scale(1);
    pointer-events: auto;
  }
}

.CommentOverlay--confirmCommentDelete {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $colorBrown;
  text-align: center;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;

  &.CommentOverlay--confirmCommentDelete__visible {
    opacity: 1;
    pointer-events: auto;
  }

  p {
    font-size: 13px;
    margin: 0.5rem 0 0.75rem 0;
  }
  button {
    border-right: 1rem;
    padding: 0.35rem 1rem;
    background: darken($colorBrown, 10%);
    color: white;
    border: 1px solid darken($colorBrown, 20%);
    &:first-of-type {
      margin-right: 1rem;
    }
  }
}

.CommentOverlay--editCommentBox {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  box-sizing: border-box;
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.15s ease-out;

  &.CommentOverlay--editCommentBox_visible {
    opacity: 1;
    pointer-events: auto;
  }
}

.CommentOverlay--editCommentBox_footer {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  .ButtonDistorted {
    padding: 0.5em 1em;
    @media (min-width: 720px) {
      padding: 0.5em 2em;
    }
  }
}

.CommentOverlay--editContainerBox {
  position: relative;
  margin-top: 4rem;
  textarea {
    overflow: auto !important;
  }
  .ModalCloseButton {
    top: -39px;
    right: 2px;
    width: 32px;
    height: 32px;
    background-size: 18px;
  }
}

.RecipeRating--commentActions {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.CommentOverlay--commentLike {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  &:focus {
    outline: 0;
  }
  &.isClicked {
    .CommentOverlay--commentLikeIcon {
      &:before { opacity: 0; }
      &:after { opacity: 1; }
    }
  }
  .CommentOverlay--commentLikeIcon {
    position: relative;
    display: block;
    width:  18px;
    height: 18px;
    margin-left: 8px;
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: inherit;
      height: inherit;
      background-size: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      transition: opacity 0.15s ease;
    }
    &:before {
      background-image: url(../../images/thumb-icon-outline.svg);
    }
    &:after {
      background-image: url(../../images/thumb-icon.svg);
      opacity: 0;
    }
  }
}
