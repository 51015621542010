.highlighted-button {
  display: block;
  font-size: 16px;
  line-height: 1.5em;
  color: #fff;
  text-decoration: inherit;
  width: fit-content;
  white-space: nowrap;
  margin: 30px auto 0;
  position: relative;
  z-index: 2;
  padding-left: 10px;
  padding-right: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #c8392d;
  border-image-source: url('../../images/base-doughs/button-bg.png');
  border-image-slice: 25 15 25 15 fill;
  border-image-width: 34px 20px 35px 20px;
  border-image-repeat: stretch;
  border-image-outset: 10px;
  background-color: #c8392d;
}
