@import "../../../shared";

.FormTextarea {
  line-height: 30px;
  font-size: 14px;;
  padding: 0 10px;
  border: none;
  box-shadow: 3px 0 13px rgba(0, 0, 0, 0.09);
  font-family: 'Lato', sans-serif;
  width: 100%;

  &::placeholder {
    color: silver;
  }
}

.FormTextarea[data-error="true"] {
  background-color: lighten(red, 40) !important;

  &::placeholder {
    color: red;
    text-transform: uppercase;
  }
}

.FormTextarea[data-block="true"] {
  width: 100%;
}

.FormTextarea[data-spaced="true"] {
  margin: 1em 0;
}

.FormTextarea[data-dark="true"] {
  background-color: #ecebeb;
  color: #000;

  &::placeholder {
    color: lighten($colorDarkBlue, 40);
  }
}

.FormTextarea[data-lowercase="true"] {
  &::placeholder {
    text-transform: none;
  }
}

.FormTextarea[data-noshadow="true"] {
  box-shadow: none;
}

.FormTextarea[data-hidden="true"] {
  display: none;
}
