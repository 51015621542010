@import "../../shared";

.RecipeDuration {
  display:inline-block;
  vertical-align: middle;
  margin: 0 10px;
  padding-left:20px;
  position: relative;
  color: $colorBrown;

  &.teaserCard {
    margin: 0;
    padding: 10px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    color: #fff;
    // background: linear-gradient(-45deg, rgba($colorBrown, 0.4), transparent);
    &:before {
      width: 17px;
      height: 17px;
      position: static;
      margin-right: 8px;
      transform: none;
      background: url(../../images/icon-clock-white.png) center center no-repeat;
    }
  }

  &:before {
    width:15px;
    height:15px;
    background: url(../../images/icon-clock.png) center center no-repeat;
    background-size: auto 100%;
    content: '';
    position:absolute;
    top:50%;
    left:0;
    transform: translateY(-50%);
  }
}
