.FormButton {
  transition: all 0.2s;
  height: 30px;
  line-height: 30px;
  width: 150px;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0 10px;
  border: none;
  box-shadow: 3px 0px 13px rgba(0, 0, 0, 0.09);
  color: #fff;
  background-color: #4e5c63;
}
.FormButton.isBrown {
  background: #904b27 !important;
}
.FormButton.isBrown:hover {
  background-color: rgb(164.0655737705, 85.4508196721, 44.4344262295) !important;
}
.FormButton:hover {
  background-color: rgb(89.2372881356, 105.2542372881, 113.2627118644);
}

.FormButton[data-light=true] {
  border: 1px solid #4e5c63;
  background-color: #fff;
  color: #4e5c63;
}
.FormButton[data-lowercase=true] {
  text-transform: none;
}

.FormButton[data-block=true] {
  display: block;
  width: 100%;
}

.FormButton[data-red=true] {
  background-color: #c8392e;
}
.FormButton[data-red=true]:hover {
  background-color: rgb(210.4024390244, 71.762195122, 61.0975609756);
}

.FormButton[data-large=true] {
  font-size: 15px;
  height: 42px;
  line-height: 42px;
}

.FormButton[disabled] {
  cursor: not-allowed;
  opacity: 0.33;
}

