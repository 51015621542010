// @import "_shared";

@import "../node_modules/css-reset/reset";
@import "../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/clearfix";
@import "../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/grid-framework";
@import "../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/grid";
@import "../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/hide-text";

* {
  box-sizing: border-box;
}

@font-face {
    font-family: 'lemon-yellow';
    src: url('./fonts/lemon_yellow_sun-webfont.woff2') format('woff2'),
         url('./fonts/lemon_yellow_sun-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/S6uyw4BMUTPHjxAwXjeu.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "DharmaGothicPBold";
  src: url('./fonts/DharmaGothicPBold/font.woff2') format('woff2'), url('./fonts/DharmaGothicPBold/font.woff') format('woff');
}

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/S6uyw4BMUTPHjx4wXg.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  font-family: 'Lato', sans-serif;
  line-height: 1.25em;
  background: #fff;
}

* {
  &:focus {
    outline: none;
  }
}

button {
  cursor:pointer;
  font-size: 1em;
  font-family: 'Lato', sans-serif;
  margin:0;
  padding:0;
}

strong {
  font-weight: 700;
}
